<template>
    <div 
      class="collapse-on-mobile"
      :class="{ 'is-expanded': expanded, 'has-transition': hasTransition, 'is-active-page': pageIndex == activePageIndex }"
    >
      <p class="collapse-permanent" :class="{ 'is-expanded': expanded }" @click="onClick">
        <span v-if="page.icon" class="title-icon">
          <Cardicon :icon="page.icon" />
        </span>
        <span>{{ page.title }}</span>
        <i class="svgicon svgicon--chevrondown">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>    
          </svg>
        </i>
      </p>
      <div class="collapse-flexible" :style="style" >
        <div class="collapse-inner" ref="domFlexibleInner">
          <h4 class="hide-on-mobile">{{ page.title }}</h4>
          <div class="page-text" v-html="page.text"></div>
          <div class="images">
            <figure 
              v-for="(image, idx) in page.pictures"
              :key="'image_' + pageIndex + '_' + idx"
            >
              <img :src="getImageSource(image)" :title="page.title" :alt="page.title" />
            </figure>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Cardicon from "@/views/home/components/list/Cardicon.vue";
import { ImageController } from "@/controller/";
import Store from "@/store";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "InfoItem",
  components: {
    Cardicon
  },
  computed: {    
  },
  created() {
    EventBus.$on("doResize", () => {
      this.resize();
    });    
  },
  data() {
    return {
      expanded: false,
      hasTransition: false,
      style: "",
      collapsMaxWidth: 1024,
      screenWidth: 0,
    }
  },
  props: {
    page: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 0
    },
    activePageIndex: {
      type: Number,
      default: 0
    }
  },  
  methods: {
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },    
    onClick() {
      if (Store.state.app.size.screen.width < 1024) {
        if (this.expanded) {
          this.collapse();
        } else {
          this.expand();
        }
      } else {
        this.$emit("onClick", this.page, this.pageIndex);
      }
    },
    collapse() {
      if (Store.state.app.size.screen.width < 1024) {
        this.style = "height: " + this.$refs.domFlexibleInner.getBoundingClientRect().height + "px;";
        this.hasTransition = true;
        this.$nextTick(() => {
          this.style = "height: 0;";
          this.expanded = false;
        });
      }
    },
    expand() {
      if (Store.state.app.size.screen.width < 1024) {
        this.style = "height: 0;";
        this.hasTransition = true;
        this.expanded = true;
        this.$nextTick(() => {
          this.style = "height: " + this.$refs.domFlexibleInner.getBoundingClientRect().height + "px;";
        });
      }
    },   
    resize() {
      if (this.screenWidth != Store.state.app.size.screen.width) {
        if (Store.state.app.size.screen.width < 1024) {
          this.style = "";
          this.expanded = false;
        } else {
          if (this.expanded) {
            this.style = "height: " + this.$refs.domFlexibleInner.getBoundingClientRect().height + "px;";
          }
        }        
      }
      this.screenWidth = Store.state.app.size.screen.width;
    }
  }
};
</script>
