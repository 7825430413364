<template>
  <div class="dropdown" @click="handleMainClick" ref="domDropdown" :class="{ 'is-foldout': foldout }">
    <p class="dropdown__label">
      <span>{{ selectedItem.title }}</span>
      <i class="svgicon svgicon--chevrondown">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
        </svg>
      </i>
    </p>
  </div>
</template>

<script>
import { dataProjects } from "@/data";
import DropdownList from "./main.js";

export default {
  name: "Dropdown",
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },
    /* dataProjects() {
      return dataProjects;
    }, */
    activeState() {
      return 1;
    },
    selectedItem() {
      return dataProjects.filter(i => i.id == this.selected)[0];
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      selected: 2,
      foldout: false
    }
  },
  methods: {
    handleMainClick: function() {
      this.foldout = !this.foldout;
      if (this.foldout) {
        DropdownList({
          items: this.items,
          parent: this,
          onSelect: this.handleSelectItem
        });
      } else {
        DropdownList.close();
      }
    },
    handleSelectItem: function(item) {
      this.selected = item.id;
      this.foldout = false;
      this.$emit("onSelectedChange", item);
    },
  }
};
</script>
