
import Store from "@/store";
import axios from "axios";

const ImageController = {

    pathLocal: "https://idm.webweb.works/cdn/img/",
    path: process.env.VUE_APP_BACKEND_URL + "/media/",
    pathItem: process.env.VUE_APP_BACKEND_URL + "/media/classification/",
    pathCards: "https://idm.webweb.works/cdn/img/cards/",

    getCardImageSrc(image) {
        let s;
        if (typeof image == 'object' && Array.isArray(image.url) && image.url.length > 2) {
            s = image.url[2];
        } else if (Array.isArray(image) && image.length) {
            s = image[2];
        } else if (typeof image == 'object') {
            s = image.url;
        } 
        return s;        
    },

    getItemImageSrc(image) {
        let s;

        if (typeof image == 'object' && Array.isArray(image.url) && image.url.length > 2) {
            s = image.url[2];
        } else if (Array.isArray(image) && image.length) {
            s = image[2];
        } else if (typeof image == 'object') {
            s = image.url;
        }

        return s;
    },

    getImageSrc(image) {
        if (Store.state.app.size.screen.width>=Store.state.app.size.screen.height) {
            return /* this.path +  */image.src_land;
        } else {
            return /* this.path + */ image.src;
        } 
    },

    async getBase64Image(url) {
        /* const reader = new FileReader();
        let rawImg;
        reader.onload = () => {
            rawImg = reader.result;
            console.log("ImageController:getBase64Image:url,rawImg", url, rawImg);
            return rawImg;
        }
        reader.readAsDataURL(url); */

        const image = await axios.get(url, {responseType: "arraybuffer"});
        const raw = Buffer.from(image.data).toString('base64');
        const base64 = "data:" + image.headers["content-type"] + ";base64," + raw;
        console.log("ImageController:getBase64Image:url,rawImg", url, base64);
        return base64;

    }
};
  
export default ImageController;