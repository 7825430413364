import { LoginDataService} from "@/dataservice";
import Store from "@/store";
import Cookie from "@/utils/Cookie";

//import { dataUser } from "@/data/";
import { ConfiguratorFormController } from "./";

const LoginController = {
    tokenCookieName: '_idm_configurator_token',
    loggedIn: false,
    token: "",

    user: {
        username: "",
        userRealname: ""
    },
    
    /** 
     * checkLogin
     * 
     * @return {Boolean} token exists
    */
     checkLogin() {            
        this.token = Cookie.readCookie(this.tokenCookieName);
        if (this.token) {
            this.loggedIn = true;
            Store.dispatch("setMode", "expert");
            this.user.username = "username";
            this.user.userRealname = "Logout";
        }
    },

    /** 
     * doLogin
     * 
     * @param {string} username
     * @param {string} password
     * 
     * @return {Boolean} log in success
    */
    async doLogin(username, password) {
        const response = await LoginDataService.tokenAuth(username, password);
        if (response.status === 200 && response.data.token) {
            this.token = response.data.token;
            this.loggedIn = true;
            Store.dispatch("setMode", "expert");
                //[WS 2022-12-19] TODO replace response if sent
            this.user.username = "username";
            this.user.userRealname = "Logout";
            Cookie.createCookie(this.tokenCookieName, response.data.token);
        } else {
            this.loggedIn = false;
        }
        return this.loggedIn;
    },

    /** 
     * doLogout
     * 
     * @return {void}
    */    
    doLogout() {
        this.loggedIn = false;
        Store.dispatch("setMode", "customer");
        Store.dispatch("setConfiguratorFormMode", "basic");
        ConfiguratorFormController.setFormMode("basic");
        Cookie.eraseCookie(this.tokenCookieName);
        this.token = "";
        console.log('Cookie after logout',Cookie.readCookie(this.cookieName));
    }
};
  
export default LoginController;