import Vue from "vue";
import loginformVue from "./Login.vue";

const LoginFormConstructor = Vue.extend(loginformVue);

let instance;

const initInstance = () => {
  instance = new LoginFormConstructor({
    el: document.createElement("div")
  });
  document.body.appendChild(instance.$el);
};

const showLoginForm = (/* options */) => {
  if (!instance) {
    initInstance();
  }

  document.body.appendChild(instance.$el);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.add('has-popup');

  Vue.nextTick(() => {
    instance.active = true;
    Vue.nextTick(() => {
      instance.initShow();
      setTimeout( () => { instance.visible = true; }, 50);
    });
  });
};

const LoginForm = function(options) {
  if (Vue.prototype.$isServer) return;

  showLoginForm(options);
};

LoginForm.show = options => {
  return LoginForm(options);
};

LoginForm.close = () => {
    instance.visible = false;
      setTimeout(() => {
        instance.active = false;
      }, 300);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.remove('has-popup');
};

export default LoginForm;
export { LoginForm };