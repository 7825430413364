
const Cookie = {

    createCookie : function(name, value, duration) {
        const date = new Date();
        date.setTime(date.getTime() + (duration?duration:3600000));// 1 hour //(days * 24 * 60 * 60 * 1000)
        const expires = "; expires=" + date.toGMTString();
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    
    readCookie : function(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
            c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    
    eraseCookie : function(name) {
        Cookie.createCookie(name, "", -1);
    },
    
};

export default Cookie;

