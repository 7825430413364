<template>
  <button class="button--dense button--icon button--close" @click="handleClick">
      <i class="svgicon svgicon--close">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
          </svg>
      </i>
  </button>
</template>

<script>

export default {
  name: "ButtonClose",
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  mounted() {},
  methods: {
    handleClick(ev) {
      this.$emit("click", ev);
    },
  }
};
</script>
