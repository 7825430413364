import Vue from "vue";
import tooltipVue from "./Tooltip.vue";

const TooltipConstructor = Vue.extend(tooltipVue);

let instance;

const initInstance = () => {
  instance = new TooltipConstructor({
    el: document.createElement("div")
  });
  document.body.appendChild(instance.$el);
  document.body.addEventListener('click', (ev) => {
    const tp = document.querySelector('.tooltip');
    if (tp) {
      if (ev.path && !ev.path.filter(p => p && p.classList && (p.classList.contains('tooltip') || p.classList.contains('quickinfo-notice')) ).length) {
        Tooltip.close();
      }
    }
  });  
};

const showTooltip = (options) => {
  if (!instance) {
    initInstance();
  }

  document.body.appendChild(instance.$el);
  //const wrapper = document.querySelector('.app-wrapper');
  //if (wrapper) wrapper.classList.add('has-popup');
  instance.options = options;
  //Vue.nextTick(() => {
  instance.active = true;
  Vue.nextTick(() => {
    instance.initShow();
    setTimeout( () => { instance.visible = true; }, 50);
  });
  //});
};

const Tooltip = function(options) {
  if (Vue.prototype.$isServer) return;
  showTooltip(options);
};

Tooltip.show = options => {
  console.log('Tooltip.show options', options);
  return Tooltip(options);
};

Tooltip.close = () => {
  if (instance) {
    instance.resetStyle();
    instance.visible = false;
      setTimeout(() => {
        instance.active = false;
      }, 100);
    }
};


  document.body.addEventListener("click", ev => {
    const composed = ev.composedPath();
    if (composed) {
      const close = composed.filter(el => el.classList && (
          el.classList.contains('button--tooltip-open') ||
          el.classList.contains('tooltip') ||
          el.classList.contains('button--tooltip-close')
        ));

      if (!close.length) {
        Tooltip.close();
      }
    }
  });  

export default Tooltip;
export { Tooltip };