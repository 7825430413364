
//import Store from "@/store";
//import { EventBus } from "@/bus/eventbus.js";

const PdfController = {
    pdf: "",
    pdfFilename: "",
    directDownload: false,

    path: process.env.VUE_APP_BACKEND_URL,

    getDownloadSchemeSource(src) {
        const a = src.split("/");
        if (a.length) {
            return /* this.path + */ src;   
        } else {
            return src;
        }
    },

    getDownloadPdfSource(src) {
        return this.path + src;
    },

    resetPdf() {
        this.pdf = "";
        this.pdfFilename = "";
    },

    /* async onPdfDownload(title, html2pdf, options, pdfContent) {
        const pdfFileName = title.replace(/[^a-z0-9]/gi, '_').toUpperCase();
         await html2pdf().set(options).from(pdfContent).toPdf().output('blob').then((pdfBlob) => {
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => {
            PdfController.pdf = reader.result;
            PdfController.pdfFilename = pdfFileName + ".pdf";
            console.log("Pdf:handleBeforeDownload:pdfFilename", pdfFileName);
            const linkSource = PdfController.pdf;
            if (PdfController.directDownload) {
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                //downloadLink.target = "_blank";
                downloadLink.download = PdfController.pdfFilename;
                downloadLink.click();
            }
            EventBus.$emit("onPdfGenerated");
          };
        });
  
    }, */

};
  
export default PdfController;