<template>
  <div class="container-chart container-donut" ref="domContainerDoughnut" >
    <h4 class="chart-title">{{ plot.title }}</h4>
    <div class="chart-summary">
      <ul class="summary-items" :data-items="visibleItems">
        <li
          v-for="(bar,idx) in plot.data"
          v-bind:key="'summaryItem'+idx"
        >
          <span class="summary-dot" :style="getBackgroundStyle(bar)"></span>
          <span class="summary-title">{{ bar.name }}</span>
        </li>
      </ul>
    </div>
    <div class="doughnut" :data-items="visibleItems">
      <SvgDoughnut :doughnutData="doughnutPieces" :chartSize="200"/>
    </div>
    <div class="stacked-values">
      <ul class="stacked-value-items" :data-items="visibleItems">
        <li
          v-for="(bar,idx) in plot.data"
          v-bind:key="'summaryItem'+idx"
        >
          <span :style="getColorStyle(bar)">{{ bar.value }} %</span>
          <span class="summary-value" :style="getColorStyle(bar)">{{ bar.title_value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"
//import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css"
//import Vue from "vue";
import SvgDoughnut from "./SvgDoughnut.vue"

//Vue.use(Donut);

export default {
  name: "Doughnut",
  components: {
    SvgDoughnut
  },
  data() {
    return {
      chartSettings: {
        size: 200,
        backgroundColor: "#24313F"
      },
    }
  },  
  computed: {
    barBottom() {
      return this.viewbox.height - this.chartSettings.xLabelHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    visibleItems() {
      return this.plot.data; //this.plot.data.filter(d => d.value != 0).length;
    },
    doughnutPieces() {
      return this.plot.data.map(e => { 
        return {
          "value": e.value,
          "color": e.color,
          "label": e.name
        };
      });
    }
  },
  watch: {
  },
  props: {
    plot: {
      type: Object,
      default: null
    },
  },
  created() {
  },
  methods: {
    getBackgroundStyle(bar) {
      return "background-color: " + bar.color + ";";
    },
    getColorStyle(bar) {
      return "color: " + bar.color + ";";
    },
    getDoughnutPartStyle(doughnut) {
      console.log('doughnut', doughnut);
      return "transform: rotate(0deg);";
    },
    getDoughnutCircleStyle(doughnut) {
      return "background-color: " + doughnut.color + "; ";
    }
  }

};
</script>