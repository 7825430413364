<template>
  <i class="svgicon" :class="iconClass" v-html="iconHtml" :data-icon="iconClass"></i>
</template>

<script>

export default {
  name: "Cardicon",
  components: {
  },
  computed: {
    iconClass() {
      return this.icons && this.icons[this.icon.toLowerCase()] ? this.icons[this.icon.toLowerCase()]["class"] : "";
    },
    iconHtml() {
      return this.icons && this.icons[this.icon.toLowerCase()] ? this.icons[this.icon.toLowerCase()]["svg"]: "";
    }
  },
  data() {
    return {
      icons: {
        "wärmepumpe-luft": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 12H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 4L11 4" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M19 16L22 16" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 16H13C14.6569 16 16 17.3431 16 19C16 20.6569 14.6569 22 13 22C12.2316 22 11.5308 21.7111 11 21.2361" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 8H18C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C17.1115 2 16.3132 2.38625 15.7639 3" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/></svg>',
          class: "svgicon--heatpumpair"
        },
        "wärmepumpe-grundwasser": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 22H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M6.90912 14.0454C6.90912 10.0909 12 5 12 5C12 5 17.0909 10.0909 17.0909 14.0454C17.0909 16.7817 14.8117 19 12 19C9.1884 19 6.90912 16.7817 6.90912 14.0454Z" stroke="#8197AC" stroke-width="2" stroke-linejoin="round"/></svg>',
          class: "svgicon--heatpumpgroundwater"
        },
        "speicher warm": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9051 1C12.9051 1 7.02182 6 12 12C16.9782 18 11.0949 23 11.0949 23" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M17.5 22C17.5 22 22.9781 17 17.9999 11C16.3468 9.00757 14 6.5 17.0947 4" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M6.90513 2C6.90513 2 1.02182 7 6 13C7.65311 14.9924 9 17.5 6.90513 19.5" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/></svg>',
          class: "storagewarm"
        },
        "speicher kalt": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1L12 6M12 23L12 18.5M12 6L9 3M12 6L15 3M12 6L12 18.5M12 18.5L9 21.5M12 18.5L15 21.5" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2.47375 6.50001L6.80388 9.00001M21.5263 17.5L17.6292 15.25M6.80388 9.00001L2.70581 10.0981M6.80388 9.00001L5.70581 4.90193M6.80388 9.00001L17.6292 15.25M17.6292 15.25L18.7273 19.3481M17.6292 15.25L21.7273 14.1519" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2.47376 17.5L6.80388 15M21.5263 6.50001L17.6292 8.75001M6.80388 15L5.70581 19.0981M6.80388 15L2.70581 13.9019M6.80388 15L17.6292 8.75001M17.6292 8.75001L21.7273 9.84809M17.6292 8.75001L18.7273 4.65193" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/></svg>',
          class: "storagecold"
        },
        "wärmepumpe-erdreich": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M6 6H14" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M6 14H10" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 10H6" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 18H8" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M14 10H18" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M12 18H18" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M18 6H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M14 14L22 14" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><path d="M2 22H22" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/><circle cx="2" cy="6" r="1" fill="#8197AC"/><circle cx="2" cy="14" r="1" fill="#8197AC"/><circle cx="10" cy="10" r="1" fill="#8197AC"/><circle cx="22" cy="10" r="1" fill="#8197AC"/><circle cx="22" cy="18" r="1" fill="#8197AC"/></svg>',
          class: "heatpumpsoil"
        },
        "warmwasser": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 15.2142C4 9 12 1 12 1C12 1 20 9 20 15.2142C20 19.5142 16.4183 23 12 23C7.58172 23 4 19.5142 4 15.2142Z" stroke="#8197AC" stroke-width="2" stroke-linejoin="round"/><path d="M9.50003 11C8.00003 12.1667 6 16.5 10.5 19" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          class: "warmwater"
        },
        "check": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          class: "check"
        },
        "synthetic": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6875 10.4999L10.0219 6.23488C9.93051 6.08869 9.77027 5.99988 9.59788 5.99988H5.08009C4.91292 5.99988 4.7568 6.08343 4.66407 6.22253L2.5625 9.37488L2.12541 10.2491C2.04751 10.4049 2.0563 10.59 2.14862 10.7377L4.66562 14.7649C4.75699 14.9111 4.91723 14.9999 5.08962 14.9999H9.875M12.6875 10.4999H17.4598C17.6393 10.4999 17.8049 10.596 17.894 10.7518L19.8672 14.205C19.9502 14.3502 19.9551 14.5272 19.8803 14.6767L17.8882 18.661C17.8035 18.8304 17.6304 18.9374 17.441 18.9374H12.9448C12.7833 18.9374 12.6318 18.8594 12.5379 18.728L9.875 14.9999M12.6875 10.4999L9.875 14.9999" stroke="#8197AC" stroke-width="2"/><path d="M20 21.9999L17 17.9999" stroke="#8197AC" stroke-width="2"/><path d="M18 10.9999L21 5.99988" stroke="#8197AC" stroke-width="2"/><path d="M4.5 5.99988L2 1.99988" stroke="#8197AC" stroke-width="2"/><circle cx="21" cy="5.99988" r="2" fill="#8197AC"/><circle cx="2" cy="1.99988" r="2" fill="#8197AC"/><circle cx="20" cy="21.9999" r="2" fill="#8197AC"/></svg>',          
          class: "synthetic"
        },
        "ecobetrieb": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 21L10.3203 13.6794L3 21Z" fill="#3DB749"/><path d="M20.9226 3.0774L21.9204 3.01089C21.8871 2.51101 21.489 2.11294 20.9891 2.07961L20.9226 3.0774ZM5.36696 18.6336L4.50946 19.1481C4.59391 19.2889 4.7117 19.4067 4.85245 19.4911L5.36696 18.6336ZM2.29288 20.2929C1.90236 20.6834 1.90238 21.3166 2.29291 21.7071C2.68344 22.0976 3.3166 22.0976 3.70712 21.7071L2.29288 20.2929ZM11.0274 14.3865C11.4179 13.996 11.4179 13.3628 11.0274 12.9723C10.6369 12.5818 10.0037 12.5818 9.61318 12.9723L11.0274 14.3865ZM19.9248 3.14391C20.3727 9.8624 18.7845 14.7822 16.2122 17.3546C14.9416 18.6252 13.4208 19.3399 11.721 19.4516C10.0097 19.5641 8.03694 19.0695 5.88147 17.7761L4.85245 19.4911C7.27217 20.943 9.64584 21.5923 11.8521 21.4473C14.0697 21.3016 16.0375 20.3578 17.6264 18.7688C20.7731 15.622 22.3875 10.0184 21.9204 3.01089L19.9248 3.14391ZM6.22447 18.1192C4.93118 15.9636 4.43661 13.9907 4.54903 12.2794C4.6607 10.5794 5.37542 9.05851 6.64598 7.78791C9.2183 5.2155 14.1379 3.62727 20.8561 4.07518L20.9891 2.07961C13.9818 1.61242 8.37842 3.22693 5.23174 6.37372C3.6428 7.96272 2.69901 9.93056 2.55333 12.1483C2.4084 14.3545 3.05763 16.7283 4.50946 19.1481L6.22447 18.1192ZM3.70712 21.7071L11.0274 14.3865L9.61318 12.9723L2.29288 20.2929L3.70712 21.7071Z" fill="#3DB749"/></svg>',
          class: "ecobetrieb"
        },
        "a+++": {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="59px" height="24px" viewBox="0 0 59 24" enable-background="new 0 0 59 24" xml:space="preserve"><path fill="#00A650" d="M0,0h49v24H0V0z M49,0l10,12L49,24V0z"/><path fill="#FFFFFF" d="M15.1,14.3h-4.4L9.9,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L12,5.5c0.1,0,0.3,0,0.4,0s0.3,0,0.5,0c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0L17.6,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.5,0c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0L15.1,14.3z M11.1,12.9h3.5L12.9,7L11.1,12.9z M22,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7H22V7.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0v2.9H26c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4h-2.6V15c-0.1,0-0.3,0-0.4,0s-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1v-2.8H22z M31.6,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7h2.7V7.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0v2.9h2.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4H33V15c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1v-2.8H31.6z M41.2,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7h2.7V7.8c0.1,0,0.2,0,0.3,0s0.2,0,0.4,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0v2.9h2.6c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4h-2.6V15c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1V12.1z"/></svg>',
          class: "a+++"
        },
        "a++": {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="59px" height="24px" viewBox="0 0 59 24" enable-background="new 0 0 59 24" xml:space="preserve"><path fill="#00A650" d="M0,0h49v24H0V0z M49,0l10,12L49,24V0z"/><path fill="#FFFFFF" d="M15.1,14.3h-4.4L9.9,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L12,5.5c0.1,0,0.3,0,0.4,0s0.3,0,0.5,0c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0L17.6,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.5,0c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0L15.1,14.3z M11.1,12.9h3.5L12.9,7L11.1,12.9z M22,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7H22V7.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0v2.9H26c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4h-2.6V15c-0.1,0-0.3,0-0.4,0s-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1v-2.8H22z M31.6,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7h2.7V7.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0v2.9h2.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4H33V15c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1v-2.8H31.6z"/></svg>',
          class: "a++"
        }, 
        "a+": {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="59px" height="24px" viewBox="0 0 59 24" enable-background="new 0 0 59 24" xml:space="preserve"><path fill="#00A650" d="M0,0h49v24H0V0z M49,0l10,12L49,24V0z"/><path fill="#FFFFFF" d="M15.1,14.3h-4.4L9.9,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L12,5.5c0.1,0,0.3,0,0.4,0s0.3,0,0.5,0c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0L17.6,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.5,0c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0L15.1,14.3z M11.1,12.9h3.5L12.9,7L11.1,12.9z M22,12.1h-2.7c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.7H22V7.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0v2.9H26c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4s0,0.2,0,0.4c0,0.1,0,0.2,0,0.4h-2.6V15c-0.1,0-0.3,0-0.4,0s-0.2,0-0.4,0c-0.3,0-0.5,0-0.7-0.1v-2.8H22z"/></svg>',
          class: "a+"
        },
        "a": {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="59px" height="24px" viewBox="0 0 59 24" enable-background="new 0 0 59 24" xml:space="preserve"><path fill="#00A650" d="M0,0h49v24H0V0z M49,0l10,12L49,24V0z"/><path fill="#FFFFFF" d="M15.1,14.3h-4.4L9.9,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L12,5.5c0.1,0,0.3,0,0.4,0s0.3,0,0.5,0c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0L17.6,17c-0.1,0-0.3,0-0.4,0s-0.3,0-0.5,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L15.1,14.3z M11.1,12.9h3.5L12.9,7L11.1,12.9z"/></svg>',
          class: "a"
        },
        "close": {
          svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 6L18 18" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          class: "close"
        }
      }
    }
  },
  watch: {
  },
  props: {
      icon: {
        type: String,
        default: ""
      },
  },
  created() {
  },
  methods: {
  },
};
</script>
