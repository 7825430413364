<template>
  <nav class="expertnav">
    <ul class="expertnav__items">
    <li v-for="item in dataMainnav"
      v-bind:key="item.id">
      <button class="button--state" :class="{ 'is-active': activeState == item.id }" @click="onClick(item)">{{ item.title }}</button>
    </li>
    </ul>
  </nav>
</template>

<script>
import { dataMainnav } from "@/data";
//import Store from "@/store";
import { ConfiguratorFormController } from "@/controller";

export default {
  name: "ExpertNav",
  data() {
    return {
      activeState: 1 
    }
  },
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },
    formMode() {
      return ConfiguratorFormController.getFormMode();
    },
    dataMainnav() {
      return dataMainnav;
    },    
  },
  watch: {
    /* mode: function(value) {
      console.log('watch mode', value);
    }, */
  },
  created() {
    //console.log('ExpertNav created', this.formMode);
  },
  mounted() {
    //console.log('ExpertNav mounted', this.formMode);
    this.activeState = (this.formMode == "basic" ? 1 : 2);
  },
  methods: {
    onClick(item) {
      if (this.activeState != item.id) {
        this.activeState = item.id;
        if (item.id == 1) { //basic mode
          ConfiguratorFormController.setFormMode("basic");
          //Store.dispatch("setConfiguratorFormMode", "basic");  
        } else if (item.id == 2) { //expert mode
          //Store.dispatch("setConfiguratorFormMode", "expert");  
          ConfiguratorFormController.setFormMode("expert");
        }
      }
    },
  }
};
</script>
