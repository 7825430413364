export default [
    {
        title: "Standort",
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 8.78585C20 15 12 23 12 23C12 23 4 15 4 8.78584C4 4.48584 7.58172 1 12 1C16.4183 1 20 4.48584 20 8.78585Z" stroke="#FFF042" stroke-width="2" stroke-linejoin="round"/><circle cx="12" cy="9" r="3" fill="#FFF042"/></svg>',
    },
    {
        title: "Heizen",
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.25 19.5C17.25 19.5 20.625 15.9205 17.25 11.625C13.875 7.32955 17.25 3.75 17.25 3.75" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 19.5C12 19.5 15.375 15.9205 12 11.625C8.625 7.32955 12 3.75 12 3.75" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.75 19.5C6.75 19.5 10.125 15.9205 6.75 11.625C3.375 7.32955 6.75 3.75 6.75 3.75" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><line x1="3" y1="20" x2="21" y2="20" stroke="#8197AC" stroke-width="2" stroke-linecap="round"/></svg>'
    },
    {
        title: "Warmwasser",
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 15.2142C4 9 12 1 12 1C12 1 20 9 20 15.2142C20 19.5142 16.4183 23 12 23C7.58172 23 4 19.5142 4 15.2142Z" stroke="#8197AC" stroke-width="2" stroke-linejoin="round"/><path d="M9.50052 11C8.00052 12.1667 6.00049 16.5 10.5005 19" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
    },
    {
        title: "Energie",
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 7V1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>        <path d="M16 7V1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 23L12 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 7H19V14C19 16.2091 17.2091 18 15 18H9C6.79086 18 5 16.2091 5 14V7Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>        </svg>'
    },
    {
        title: "Zusatzinfo",
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="22" height="22" stroke="#8197AC" stroke-width="2" stroke-linejoin="round"/><path d="M5 7H18.5" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 12H14" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 17H17" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
    }
];