<template>
  <nav class="resultviewnav" :data-active-page="activeDetailPage">
    <ul class="resultviewnav__items">
      <li v-for="page in pageEntries"
        v-bind:key="page.uniqeId">
        <button class="button--state" :data-pageid="page.page_id" :class="{ 'is-active': activePageId == page.uniqeId }" @click="handlePageButtonClick(page)">{{ page.menu_name }}</button>
      </li>
    </ul>
    <div class="resultviewnav__slide">
      <button class="button--icon" @click="handleSlideButtonClick">
        <i class="svgicon svgicon--chevron-right">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z" fill="white"/></svg>
        </i>
      </button>
    </div>
  </nav>  
</template>

<script>

import { ConfiguratorResultController } from "@/controller";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "ResultviewNav",
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },
    activeDetailPage() {
      return this.$store.state.app.detailPage;
    },    
  },
  data() {
    return {
      pageEntries: [],
      activePageId: 0,
    }
  },
  created() {
    EventBus.$on("onShowDetail", item => {
      ConfiguratorResultController.setPages(item.pages);
      this.pageEntries = ConfiguratorResultController.pages;
      this.activePageId = this.pageEntries[0].uniqeId;
      EventBus.$emit("onPageChange", this.pageEntries[0]);
    });
  },
  methods: {
    handlePageButtonClick(page) {
      this.activePageId = page.uniqeId;
      EventBus.$emit("onPageChange", page);
    },
    handleSlideButtonClick() {
      console.log("ResultViewNav:handleSlideButtonClick");
    }
  }
};
</script>
