<template>
  <div class="container-chart container-bar" ref="domContainerChart">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" :width="chartWidth" :height="chartHeight" :viewBox="viewboxStr" xml:space="preserve" >
      <rect 
        v-for="(line, idx) in barYLines"
        v-bind:key="'line'+idx"
        :x="chartSettings.yLabelWidth"
        :y="line.y-1"
        :width="chartWidth"
        height="2"
        class="bar-line"
        :data-value="line.value"
        fill="#000"
      />
      
      <text x="0" :y="chartSettings.yAxisLabelHeight - chartSettings.axisLabelLineHeight" class="axis-label">{{ plot.ylabel }}</text>
      <text x="0" :y="xAxisLabelTop" class="axis-label">{{ plot.xlabel }}</text>
      <text 
        v-for="(line,idx) in barYLines"
        v-bind:key="'yLabel'+idx"
        :x="0"
        :y="line.y+5"
        class="axis-value axis-value--y"
      >
      {{ (line.value?line.value:'') }}
      </text>
      <text 
        v-for="(value,idx) in barXValues"
        v-bind:key="'xLabel'+idx"
        :x="value.x"
        :y="xAxisLabelTop"
        class="axis-value axis-value--x"
      >
      {{ value.value }}
      </text>
      <g 
        v-for="(el, idx) in plot.data"
        v-bind:key="'planeDiagramEl'+idx"
        :data-type="el.type"
      >
        <line v-if="el.type=='line-dashed'" :x1="getX(el.data[0].x)" :y1="getY(el.data[0].y)" :x2="getX(el.data[1].x)" :y2="getY(el.data[1].y)" :stroke-width="chartSettings.dotLineWidth" :stroke-dasharray="chartSettings.dashArray" :style="getStrokeStyle(el)" />
        <polyline v-if="el.type=='line'" :points="getPolylinePoints(el.data)" :style="getStrokeStyle(el)" />
        <polygon v-if="el.type=='area'" :points="getPolygonPoints(el.data)" :style="getStrokeFillStyle(el)" :fill-opacity="chartSettings.alpha" />
        <circle 
          v-if="el.type=='point'" 
          :cx="getX(el.x?el.x:(el.data.x?el.data.x:0))" 
          :cy="getY(el.y?el.y:(el.data.y?el.data.y:0))" 
          :r="chartSettings.dotRadius" 
          :style="getFillStyle(el)" 
        />
      </g>
    </svg>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"

export default {
  name: "PdfPlaneDiagram",
  components: {
  },
  data() {
    return {
      chartWidth: 544,
      chartHeight: 280,
      viewboxStr: "0 0 544 280",
      chartSettings: {
        yAxisGap: 7,
        dotRadius: 8,
        xAxisGap: 0,
        dotLineWidth: 2,
        dashArray: 5,
        xLabelHeight: 8,
        yLabelWidth: 46,
        yAxisLabelHeight: 16,
        axisLabelLineHeight: 6,
        alpha: 0.4,
        yLineSteps: 2.5,
        xValueSteps: 5
      },
      tooltip: {
        show: false,
        x: 0,
        y: 0,
        valueX: 0,
        valueY: 0,
        text: ""
      },
      barXValues: [],
      barYLines: [],
    }
  },  
  computed: {
    barBottom() {
      return this.chartHeight - this.chartSettings.xLabelHeight - this.chartSettings.axisLabelLineHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    xAxisLabelTop() {
      return this.chartHeight;
      //return this.viewbox.height - this.chartSettings.xLabelHeight + this.chartSettings.axisLabelLineHeight;
    },

  },
  watch: {
  },
  props: {
    plot: {
      type: Object,
      default: null
    },
  },
  created() {
    let max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineSteps; //TODO: maybe every chart is with 900 like in the design
    this.chartSettings.yAxisGap = parseInt((this.chartHeight - this.chartSettings.xLabelHeight - this.chartSettings.yAxisLabelHeight - this.chartSettings.axisLabelLineHeight) / max / this.chartSettings.yLineSteps);
    this.chartSettings.xAxisGap = this.getDiagramWidth() / this.getSumMinMax();       

    this.barXValues = [];
    for(let i = this.plot.viewport.x_min; i <= this.plot.viewport.x_max; i++ ) {
      if (i % this.chartSettings.xValueSteps == 0) {
        this.barXValues.push({
          value: i,
          x: this.getX(i) - (i <=- 10 ? 12 : (i < 0 ? 8 : (i > 10 ? 10 : 6)))
        });
      } 
    };

    this.barYLínes = [];
    const min = parseInt(this.plot.viewport.y_min) / this.chartSettings.yLineSteps; //TODO: are -100 values possible?
    max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineSteps; //TODO: maybe every chart is with 900 like in the design
    for (let i = min; i <= max; i++ ) {
      this.barYLines.push({
        y: this.barBottom - (i*this.chartSettings.yLineSteps*this.chartSettings.yAxisGap),
        value: i*this.chartSettings.yLineSteps
      });
    }
  },
  mounted() {
    
  },
  methods: {
    getSumMinMax() {
      return parseFloat(this.plot.viewport.x_min)*(parseFloat(this.plot.viewport.x_min)<0?-1:1) + parseFloat(this.plot.viewport.x_max)
    },
    getViewPortWidth() {
      return this.chartWidth;
    },
    getDiagramWidth() {
      //return this.getViewPortWidth() - this.chartSettings.yLabelWidth;
      return this.chartWidth - this.chartSettings.yLabelWidth;
    },
    getViewPortHeight() {
      //return parseFloat(this.plot.viewport.y_max*this.chartSettings.yAxisGap) + this.chartSettings.xLabelHeight + this.chartSettings.yAxisLabelHeight;
      return this.chartHeight;
    },
    getDeltaX() {
      const delta = (0 - parseFloat(this.plot.viewport.x_min));
      return delta;
    },
    getY(y) {
      return this.barBottom - parseFloat(y)*this.chartSettings.yAxisGap;
    },
    getX(x) {
      const left = this.chartSettings.yLabelWidth + (this.getDeltaX() + parseFloat(x))*this.chartSettings.xAxisGap; //(this.getViewPortWidth()/this.getSumMinMax());
      return left;
    },
    getHeight(bar) {
      return bar.y;
    },
    getFillStyle(el) {
      return "fill: " + el.color + "; ";
    },
    getStrokeStyle(el) {
      return "stroke: " + el.color + "; fill: none; ";
    },
    getStrokeFillStyle(el) {
      return "stroke: " + el.color + "; fill: " + el.color + "; ";
    },
    getPolylinePoints(data) {
      let str = "";
      data.forEach(point => {
        str += this.getX(point.x) + "," + this.getY(point.y) + " "
      });
      return str;
    },
    getPolygonPoints(data) {
      let str = "";
      data.forEach(point => {
        str += this.getX(point.x) + ", " + this.getY(point.y1) + " ";
      });
      for (let i = data.length-1; i >= 0; i--) {
        str += this.getX(data[i].x) + ", " + this.getY(data[i].y2) + " ";
      }
      return str;
    }
  }

};
</script>