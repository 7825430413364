import { Api } from "@/api";
import axios from "axios";

const ContentDataService = {
    
  async fetchData() {
    const url = Api.BACKEND_URL + Api.CONTENT + "?lang=de";
    let response;
    try {
        response = await axios.create({ withCredentials: false }).get(url);
        if (response.status == 200) {
          console.log("ContentDataService:fetchData:response", response);
          return response;
        }
    } catch (err) {
      console.error(err);
    }
    return this.data;
    
  },

};


export default ContentDataService;