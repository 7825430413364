<template>
    <div class="dropdownlist" :class="{ 'is-active': active, 'is-foldout': foldout, 'has-transition': hasTransition }" :style="listStyle" @transitionend="handleTransitionEnd">
      <ul class="dropdownlist__items" ref="domDropdownlist">
        <li v-for="item in items"
          :key="item.id"
          v-bind="item"
          class="dropdownlist__item"
          :class="{ 'is-selected': item.id == selected }"
          @click.stop="handleItemClick(item)"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
</template>

<script>

export default {
  name: "DropdownList",
  computed: {
    activeState() {
      return 1;
    },
    selectedItem() {
      return this.items.filter(i => i.id == this.selected)[0];
    },
    listStyle() {
      let style = "left: " + this.pos.left + "px; top: " + 
        this.pos.top + "px; width: " + 
        (typeof this.pos.width === "string" && this.pos.width.indexOf('%')!=-1?this.pos.width + "; ":this.pos.width + "px; ") + 
        "height: " + this.pos.height + "px;";
      return style;
    }
  },
  props: {
  },
  data() {
    return {
      items: [],
      active: false,
      selected: 2,
      foldout: false,
      hasTransition: false,
      parent: null,
      onSelect: null,
      pos: {
        left: 0,
        top: 0,
        width: "100%",
        height: 0
      }
    }
  },
  methods: {
    handleItemClick: function(item) {
      this.selected == item.id;
      this.$emit("onSelectItem", item);
      this.hide();
      if (this.onSelect && typeof this.onSelect === 'function') this.onSelect(item);
    },
    handleTransitionEnd: function() {
      this.hasTransition = false;
      if (!this.foldout) this.active = false;
    },
    initShow: function() {
      if (this.parent && this.parent.$refs.domDropdown) {
        const br = this.parent.$refs.domDropdown.getBoundingClientRect();
        this.pos.left = br.left;
        this.pos.top = br.bottom;
        this.pos.width = br.width;
      }
      this.setHeight();
      this.hasTransition = true;
      this.foldout = true;
    },
    hide: function() {
      this.setHeight();
      this.hasTransition = true;
      this.pos.height = 0;
      this.foldout = false;
    },
    setHeight() {
      this.hasTransition = false;
      if (this.$refs.domDropdownlist) {
        const h = this.$refs.domDropdownlist.getBoundingClientRect().height;
        this.pos.height = h;
      }
    }
  }
};
</script>
