<template>
  <main class="appmain" :class="{ 'service-page': displayGdpr }" >
    <router-view :key="key" />
  </main>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "AppMain",
  data() {
    return {
      displayGdpr: false
    } 
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  created() {
    EventBus.$on("onShowGdpr", () => {
      this.displayGdpr = true;
    }); 
    EventBus.$on("onHideGdpr", () => {
      this.displayGdpr = false;
    });    
  },
  methods: {    
  }
};
</script>
