import router from "./router";
import { NavigationDataService, LoginDataService } from "@/dataservice/";

//const whiteList = ["/login", "/auth-redirect"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (!LoginDataService.loginChecked) {
    LoginDataService.check();
  }
  if (!NavigationDataService.navigationLoade) {
    NavigationDataService.get();
  }
  /* if (Store.getters.gFooternav.length == 0) {
    LoaderFooternav.get();
  } */
  /* if (to.meta) {
    Store.dispatch("setPageOverflow", to.meta.hasOverflow);
  } */
  next();
});

router.afterEach(() => {
  //unsetLoading();
  //NProgress.done() // finish progress bar
});