<template>
  <button
    class="button--help button--mobile"
    @click="onToggleMobileHelp"
  >
    <i class="svgicon svgicon--help">
      <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.33301 11.0002H7.66634V9.66683H6.33301V11.0002ZM6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM6.99967 12.3335C4.05967 12.3335 1.66634 9.94016 1.66634 7.00016C1.66634 4.06016 4.05967 1.66683 6.99967 1.66683C9.93967 1.66683 12.333 4.06016 12.333 7.00016C12.333 9.94016 9.93967 12.3335 6.99967 12.3335ZM6.99967 3.00016C5.52634 3.00016 4.33301 4.1935 4.33301 5.66683H5.66634C5.66634 4.9335 6.26634 4.3335 6.99967 4.3335C7.73301 4.3335 8.33301 4.9335 8.33301 5.66683C8.33301 7.00016 6.33301 6.8335 6.33301 9.00016H7.66634C7.66634 7.50016 9.66634 7.3335 9.66634 5.66683C9.66634 4.1935 8.47301 3.00016 6.99967 3.00016Z" fill="#8197AC"/>
      </svg>
    </i>
  </button>
</template>

<script>

import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "MobileHelpToggle",
  computed: {
  },
  props: {
  },
  watch: {
  },
  created() {
    
  },
  methods: {
    onToggleMobileHelp() {
      EventBus.$emit("onToggleHelp", "mobile" );
    },    
  }
};
</script>
