import { Api } from "@/api";
import axios from "axios"; 
/* import router from "@/router";
import Store from "@/store";
import Backend from "@/utils/Backend.js"; */
//import { dataFormCustomerDe, dataFormExpertDe } from "@/data";

const RequestDataService = {

    data: [],
    values: [],
    sessionStoredData: null,

    valueDefaults: {
      "number": 0,
      "radio_horizontal": "",
      "radio_vertical": 0,
      "select_multiple_vertical": []
    },



    async fetchOrganizations(lat, lng, country) {
      if (lat && lng && country) {
        const url = Api.BACKEND_URL + Api.ORGANIZATION_SEARCH + "?lat=" + lat + "&lng=" + lng + "&country=" + country;
        let response;
        try {
          response = await axios.create({ withCredentials: false }).get(url);
          if (response.status == 200) {
            this.data = response.data.result;
          }
        } catch (err) {
          console.error(err);
        }

        return this.data;
      } else {
        return [];
      }
        
    }, 

    async postRequest(formData, token) {
      console.log('RequestDataservice:postRequest token', token);
      const url = Api.BACKEND_URL + Api.USER_REQUEST;
      let response;
      try {
        if (token) {
          response = await axios.create({ withCredentials: false }).post(
            url, 
            formData, {
              headers: {
                'Authorization': `Token ${token}`
            }
          });
        } else {
          response = await axios.create({ withCredentials: false }).post(url, formData);
        }

        return response;
      } catch (err) {
        console.error(err);
        return response;
      }
    },

  };
  
  export default RequestDataService;