import { GooglePlacesDataService} from "@/dataservice";

const GoolgePlacesController = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY, //"AIzaSyAKpUc8JMy7of7kAnIANznjQODSTUvQvDo",

    fetchPlaces(inputStr) {
        return GooglePlacesDataService.fetchPlaces(inputStr);
    }
}

export default GoolgePlacesController;