<template>
  <div class="container-chart container-bar" ref="domContainerBar" :style="containerStyle">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" :width="chartWidth" :height="chartHeight" :viewBox="viewboxStr" xml:space="preserve" @click="svgClicked">
    <rect 
      v-for="(line, idx) in barYLines"
      v-bind:key="'line'+idx"
      :x="chartSettings.yLabelWidth"
      :y="line.y-1"
      :width="yLinesWidth"
      height="2"
      class="bar-line"
      :data-value="line.value"
      fill="#000"
    />
    <text x="0" :y="chartSettings.yAxisLabelHeight - chartSettings.axisLabelLineHeight" class="axis-label">{{ plot.ylabel }}</text>
    <text x="0" :y="xAxisLabelTop" class="axis-label">{{ plot.xlabel }}</text>
    <text 
      v-for="(line,idx) in barYLines"
      v-bind:key="'yLabel'+idx"
      :x="0"
      :y="line.y+5"
      class="axis-value axis-value--y"
    >
    {{ (line.value?line.value:'') }}
    </text>
    <text 
      v-for="(value,idx) in barXValues"
      v-bind:key="'xLabel'+idx"
      :x="value.x"
      :y="xAxisLabelTop"
      class="axis-value axis-value--x"
    >
    {{ value.value }}
    </text>
    <rect 
      v-for="(bar, idx) in plot.data" 
      v-bind:key="'bar'+idx" 
      :x="getX(idx)" 
      :y="getY(bar)" 
      :width="chartSettings.width*chartSettings.xFactor" 
      :height="getHeight(bar)" 
      :fill="barColor" 
      class="bar"
      :data-y="bar.y"
      :data-x="bar.x"
      @mouseenter="setTooltip($event, bar)"
      @mouseleave="unsetTooltip"
      @mousemove="setTooltip($event, bar)"
      @click="setTooltip($event, bar)"
      />
      </svg>
      <div class="chart-tooltip" :class="{ 'is-active': tooltip.show }" :style="tooltipStyle" ref="domChartTooltip"><p v-html="tooltipText"></p></div>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"
import Store from "@/store";

export default {
  name: "Bar",
  components: {
  },
  data() {
    return {
      chartSettings: {
        gap: 4,
        width: 12,
        xLabelHeight: 32,
        yLabelWidth: 46,
        yAxisLabelHeight: 50,
        axisLabelLineHeight: 30,
        axisValueLineHeight: 22,
        alpha: 0.4,
        yLineSteps: 100,
        yLineValueSteps: 100,
        xValueSteps: 5,
        xFactor: 1
      },
      tooltip: {
        show: false,
        x: 0,
        y: 0,
        valueX: 0,
        valueY: 0
      }
      
    }
  },  
  computed: {
    viewbox() {
      return {
        x: 0,
        y: 0,
        width: (this.plot && this.plot.viewport?this.getViewPortWidth():1000),
        height: (this.plot && this.plot.viewport?this.getViewPortHeight():1000)
      }
    },
    viewboxStr() {
      //console.log("Bar:viewBoxStr", this.plot.viewport);
      if (!this.plot.viewport) {
        return "0 0 500 500";
      } else {
        return this.viewbox.x + " " + this.viewbox.y + " " + this.viewbox.width + " " + this.viewbox.height;
      }
    },
    barBottom() {
      //console.log("this.chartHeight / this.chartSettings.xLabelHeight", this.chartHeight, this.chartSettings.xLabelHeight);
      return this.chartHeight - this.chartSettings.xLabelHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    barYLines() {
      const min = parseInt(this.plot.viewport.y_min) / this.chartSettings.yLineValueSteps; //TODO: are -100 values possible?
      const max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineValueSteps; //TODO: maybe every chart is with 900 like in the design
      //console.log("Bar:barYLines:min:max", min, parseInt(this.plot.viewport.y_min), max, parseInt(this.plot.viewport.y_max));
      //this.chartSettings.yLineSteps = (this.chartHeight - this.barBottom) / max;
      const lines = [];
      for (let i = min; i <= max; i++ ) {
        lines.push({
          y: this.barBottom - (i*this.chartSettings.yLineSteps),
          value: i*this.chartSettings.yLineValueSteps
        });
      }
      //console.log("Bar:barYLines", lines);
      return lines;
    },
    barXValues() {
      const values = [];
      this.plot.data.forEach((bar, idx) => {
        if (bar.x % this.chartSettings.xValueSteps == 0) values.push({
          index: idx,
          value: bar.x,
          x: this.getX(idx)
        });
      });
      return values;
    },
    xAxisLabelTop() {
      return this.viewbox.height - this.chartSettings.xLabelHeight + this.chartSettings.axisLabelLineHeight;
    },
    tooltipStyle() {
      return "left: " + this.tooltip.x + "px; top: " + this.tooltip.y + "px;";
    },
    tooltipText() {
      return this.plot.tooltip.replace('{x}', this.tooltip.valueX).replace('{y}', this.tooltip.valueY);
    },
    containerStyle() {
      console.log("Bar:containerStyle:chartHeight", this.chartHeight);
      return (this.chartHeight ? "height: " + this.chartHeight + "px;" : "");
    },
    yLinesWidth() {
      return this.chartWidth ? this.chartWidth - this.chartSettings.yLabelWidth : 1;
    }
  },
  watch: {
    chartHeight: function() {
      //const min = parseInt(this.plot.viewport.y_min) / this.chartSettings.yLineValueSteps; //TODO: are -100 values possible?
      const max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineValueSteps; //TODO: maybe every chart is with 900 like in the design
      this.chartSettings.yLineSteps = parseInt((this.chartHeight - this.chartSettings.xLabelHeight - this.chartSettings.axisLabelLineHeight) / max);
      //console.log("this.chartSettings.yLineSteps", parseInt(this.plot.viewport.y_max), this.chartSettings.yLineSteps);
    },
    chartWidth: function() {
      const sumMinMax = parseFloat(this.plot.viewport.x_min)*(parseFloat(this.plot.viewport.x_min)<0?-1:1) + parseFloat(this.plot.viewport.x_max);
      const cWidth = sumMinMax*(this.chartSettings.gap + this.chartSettings.width) + this.chartSettings.yLabelWidth;
      this.chartSettings.xFactor = this.chartWidth / cWidth;
      //console.log("Bar:chartWidth:watch:this.charWidth:sumMinMax:cWidth", this.chartWidth, sumMinMax, cWidth, this.chartSettings.xFactor, this.plot.viewport);
    },
  },
  props: {
    plot: {
      type: Object,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    chartHeight: {
      type: Number,
      default: 0
    },
    chartWidth: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (Store.state.app.size.screen.width < 1024) {
      this.chartSettings.xLabelHeight = 16;
      this.chartSettings.axisLabelLineHeight = 16;
      this.chartSettings.yLabelWidth = 24;
      this.chartSettings.yAxisLabelHeight = 24;
    }
  },
  methods: {
    getViewPortWidth() {
      //const sumMinMax = parseFloat(this.plot.viewport.x_min)*(parseFloat(this.plot.viewport.x_min)<0?-1:1) + parseFloat(this.plot.viewport.x_max);
      //return sumMinMax*(this.chartSettings.gap + this.chartSettings.width) + this.chartSettings.yLabelWidth;
      return this.chartWidth;
    },
    getViewPortHeight() {
      //return parseFloat(this.plot.viewport.y_max) + this.chartSettings.xLabelHeight + this.chartSettings.yAxisLabelHeight;
      return this.chartHeight;
    },
    getY(bar) {
      return this.barBottom - (bar.y / this.chartSettings.yLineValueSteps * this.chartSettings.yLineSteps);
    },
    getX(index) {
      /* let x = 0;
      if (bar.x < 0) {
        x = parseInt(this.plot.viewport.x_min) + parseFloat(bar.x)*-1;
      } else {
        x = parseInt(this.plot.viewport.x_min) + parseFloat(bar.x);
      }
      console.log('bar.x / x / index', bar.x, x, index); */
      return (index * (this.chartSettings.gap*this.chartSettings.xFactor + this.chartSettings.width*this.chartSettings.xFactor)) + this.chartSettings.yLabelWidth;
    },
    getHeight(bar) {    
      return bar.y / this.chartSettings.yLineValueSteps * this.chartSettings.yLineSteps;
    },
    setTooltip(ev, bar) {
      this.tooltip.x = ev.clientX - this.$refs.domContainerBar.getBoundingClientRect().x - this.$refs.domChartTooltip.clientWidth - 16;
      this.tooltip.y = ev.clientY - this.$refs.domContainerBar.getBoundingClientRect().y - this.$refs.domChartTooltip.clientHeight - 16;
      this.tooltip.valueX = bar.x;
      this.tooltip.valueY = bar.y;
      this.tooltip.show = true;
    },
    unsetTooltip() {
      this.tooltip.show = false;
    },
    svgClicked(ev) {
      if (ev.target.nodeName == 'svg') this.unsetTooltip();
    }
  }

};
</script>