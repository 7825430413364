import Vue from "vue";
import dropdownList from "./DropdownList.vue";

const DropdownListConstructor = Vue.extend(dropdownList);

let instance;

const initInstance = () => {
  instance = new DropdownListConstructor({
    el: document.createElement("div")
  });
  document.body.appendChild(instance.$el);
};

const showDropdownlist = (options) => {
  if (!instance) {
    initInstance();
  }

  document.body.appendChild(instance.$el);
  //const wrapper = document.querySelector('.app-wrapper');
  //if (wrapper) wrapper.classList.add('has-popup');
  if (options.items) instance.items = options.items;
  if (options.parent) instance.parent = options.parent;
  if (options.onSelect) instance.onSelect = options.onSelect;
  //instance.options = options;
  //Vue.nextTick(() => {
  instance.active = true;
  Vue.nextTick(() => {
    instance.initShow();
    setTimeout( () => { instance.visible = true; }, 50);
  });
  //});
};

const DropdownList = function(options) {
  if (Vue.prototype.$isServer) return;
  showDropdownlist(options);
};

DropdownList.show = options => {
  return DropdownList(options);
};

DropdownList.close = () => {
  instance.hide();
};

export default DropdownList;
export { DropdownList };