/* import { Api } from "@/api"; */
import axios from "axios";
/* import router from "@/router"; 
import Store from "@/store"; */
/* import Backend from "@/utils/Backend.js"; */


const GooglePlacesDataService = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY, //"AIzaSyAKpUc8JMy7of7kAnIANznjQODSTUvQvDo",

    data: [],
    values: [],

    async fetchPlaces(inputStr) {
      var config = {
        method: 'get',
        url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + inputStr + '&key=' + this.apiKey,
        headers: { }
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });        
    },

    createValues() {
        this.data.forEach(d => {
            this.values.push(this.createFieldValues(d));
        });
        console.log('this.values', this.values);
    },

    createFieldValues(data) {
        const r = [];
        data.fields.forEach(d => {
            r.push({
                    [d.name]: ""
                }
            );
        });
        return r;
    },


  };
  
  export default GooglePlacesDataService;