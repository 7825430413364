const PostData = {
    build(data, prefix) {
      var postData = "";
      for (var key in data) {
        if (Array.isArray(data[key])) {
            //the api wants for every entry key = value pair
          data[key].forEach(v => {
            if (v) postData += (postData.length ? "&" : "") + key + "=" + (v?encodeURIComponent(v):'')
          });
        } else {
          postData += (postData.length ? "&" : "") + key + "=" + (data[key]?encodeURIComponent(data[key]):'');
        }
      }
      //postData = postData.replace(/&/g, "&" + (prefix?prefix:''));
      postData = (prefix?prefix:'') + postData;
      return postData;
    }
  };
  
  export default PostData;