export default {
    login: "Partnerlogin",
    close: "Schliessen",
    do_login: "Login",
    logout: "Abmelden",
    forgot_password: "Passwort vergessen?",
    next: "Weiter",
    back: "Zurück",
    results: "Ergebnisse anzeigen",
    yes: "Ja",
    no: "Nein",
    update: "Aktualisieren",
    show_more: "mehr anzeigen",
    show_less: "weniger anzeigen",
    off: "Aus",
    on: "Ein",
    slide_next: "weiter",
    slide_prev: "zurück"
}