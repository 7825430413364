<template>
  <div class="container-chart container-bar" ref="domContainerChart">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" :width="chartWidth" :height="chartHeight" :viewBox="viewboxStr" xml:space="preserve" @click="svgClicked">
      <rect 
        v-for="(line, idx) in barYLines"
        v-bind:key="'line'+idx"
        :x="chartSettings.yLabelWidth"
        :y="line.y-1"
        :width="chartWidth"
        height="2"
        class="bar-line"
        :data-value="line.value"
        fill="#000"
      />
      
      <text x="0" :y="chartSettings.yAxisLabelHeight - chartSettings.axisLabelLineHeight" class="axis-label">{{ plot.ylabel }}</text>
      <text x="0" :y="xAxisLabelTop" class="axis-label">{{ plot.xlabel }}</text>
      <text 
        v-for="(line,idx) in barYLines"
        v-bind:key="'yLabel'+idx"
        :x="0"
        :y="line.y+5"
        class="axis-value axis-value--y"
      >
      {{ (line.value?line.value:'') }}
      </text>
      <text 
        v-for="(value,idx) in barXValues"
        v-bind:key="'xLabel'+idx"
        :x="value.x"
        :y="xAxisLabelTop"
        class="axis-value axis-value--x"
      >
      {{ value.value }}
      </text>
      <g 
        v-for="(el, idx) in plot.data"
        v-bind:key="'planeDiagramEl'+idx"
        :data-type="el.type"
      >
        <line v-if="el.type=='line-dashed'" :x1="getX(el.data[0].x)" :y1="getY(el.data[0].y)" :x2="getX(el.data[1].x)" :y2="getY(el.data[1].y)" :stroke-width="chartSettings.dotLineWidth" :stroke-dasharray="chartSettings.dashArray" :style="getStrokeStyle(el)" />
        <polyline v-if="el.type=='line'" :points="getPolylinePoints(el.data)" :style="getStrokeStyle(el)" />
        <polygon v-if="el.type=='area'" :points="getPolygonPoints(el.data)" :style="getStrokeFillStyle(el)" :fill-opacity="chartSettings.alpha" />
        <circle 
          v-if="el.type=='point'" 
          :cx="getX(el.x?el.x:(el.data.x?el.data.x:0))" 
          :cy="getY(el.y?el.y:(el.data.y?el.data.y:0))" 
          :r="chartSettings.dotRadius" 
          :style="getFillStyle(el)" 
          @mouseenter="setTooltip($event, el)"
          @mouseleave="unsetTooltip"
          @mousemove="setTooltip($event, el)"
          @click="setTooltip($event, el)"
        />
      </g>
    </svg>
    <div class="chart-tooltip" :class="{ 'is-active': tooltip.show }" :style="tooltipStyle" ref="domChartTooltip"><p v-html="tooltipText"></p></div>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"
import Store from "@/store";

export default {
  name: "PlaneDiagram",
  components: {
  },
  data() {
    return {
      chartSettings: {
        yAxisGap: 20,
        dotRadius: 8,
        xAxisGap: 0,
        dotLineWidth: 2,
        dashArray: 5,
        xLabelHeight: 6,
        yLabelWidth: 46,
        yAxisLabelHeight: 50,
        axisLabelLineHeight: 30,
        alpha: 0.4,
        yLineSteps: 2.5,
        xValueSteps: 5
      },
      tooltip: {
        show: false,
        x: 0,
        y: 0,
        valueX: 0,
        valueY: 0,
        text: ""
      }
    }
  },  
  computed: {
    viewbox() {
      return {
        x: 0,
        y: 0,
        width: (this.plot && this.plot.viewport?this.getViewPortWidth():1000),
        height: (this.plot && this.plot.viewport?this.getViewPortHeight():1000)
      }
    },
    viewboxStr() {
      if (!this.plot.viewport) {
        return "0 0 934 535";
      } else {
        return this.viewbox.x + " " + this.viewbox.y + " " + this.viewbox.width + " " + this.viewbox.height;
      }
    },
    barBottom() {
      return this.chartHeight - this.chartSettings.xLabelHeight - this.chartSettings.axisLabelLineHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    barYLines() {
      const min = parseInt(this.plot.viewport.y_min) / this.chartSettings.yLineSteps; //TODO: are -100 values possible?
      const max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineSteps; //TODO: maybe every chart is with 900 like in the design
      const lines = [];
      for (let i = min; i <= max; i++ ) {
        lines.push({
          y: this.barBottom - (i*this.chartSettings.yLineSteps*this.chartSettings.yAxisGap),
          value: i*this.chartSettings.yLineSteps
        });
      }
      //console.log("Bar:barYLines", lines);
      return lines;
    },
    barXValues() {
      const values = [];
      for(let i = this.plot.viewport.x_min; i <= this.plot.viewport.x_max; i++ ) {
        if (i % this.chartSettings.xValueSteps == 0) {
          values.push({
            value: i,
            x: this.getX(i) - (i <=- 10 ? 12 : (i < 0 ? 8 : (i > 10 ? 10 : 6)))
          });
        } 
      };
      return values;
    },
    xAxisLabelTop() {
      return this.chartHeight;
      //return this.viewbox.height - this.chartSettings.xLabelHeight + this.chartSettings.axisLabelLineHeight;
    },
    tooltipStyle() {
      return "left: " + this.tooltip.x + "px; top: " + this.tooltip.y + "px;";
    },
    tooltipText() {
      return this.tooltip.text.replace('{x}', this.tooltip.valueX).replace('{y}', this.tooltip.valueY);
    }
  },
  watch: {
    chartHeight: function() {
      const max = parseInt(this.plot.viewport.y_max) / this.chartSettings.yLineSteps; //TODO: maybe every chart is with 900 like in the design
      //this.chartSettings.yAxisGap = parseInt((this.chartHeight - this.chartSettings.xLabelHeight) / max);      
      this.chartSettings.yAxisGap = parseInt((this.chartHeight - this.chartSettings.xLabelHeight - this.chartSettings.yAxisLabelHeight - this.chartSettings.axisLabelLineHeight) / max / this.chartSettings.yLineSteps);
      //console.log("yAxisGap", parseInt(this.plot.viewport.y_max), this.chartSettings.yAxisGap);
    },
    chartWidth: function() {
      this.chartSettings.xAxisGap = this.getDiagramWidth() / this.getSumMinMax();      
    },    
  },
  props: {
    plot: {
      type: Object,
      default: null
    },
    chartHeight: {
      type: Number,
      default: 0
    },
    chartWidth: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (Store.state.app.size.screen.width < 1024) {
      //this.chartSettings.xLabelHeight = 16;
      this.chartSettings.axisLabelLineHeight = 16;
      this.chartSettings.yLabelWidth = 24;
      this.chartSettings.yAxisLabelHeight = 24;
    }    
  },
  mounted() {
    
  },
  methods: {
    getSumMinMax() {
      return parseFloat(this.plot.viewport.x_min)*(parseFloat(this.plot.viewport.x_min)<0?-1:1) + parseFloat(this.plot.viewport.x_max)
    },
    getViewPortWidth() {
      //TODO: hardcoded as in design
      //const sumMinMax = this.getSumMinMax();
      //return sumMinMax*(this.chartSettings.gap + this.chartSettings.width) + this.chartSettings.yLabelWidth;
      //return 934;
      return this.chartWidth;
    },
    getDiagramWidth() {
      //return this.getViewPortWidth() - this.chartSettings.yLabelWidth;
      return this.chartWidth - this.chartSettings.yLabelWidth;
    },
    getViewPortHeight() {
      //return parseFloat(this.plot.viewport.y_max*this.chartSettings.yAxisGap) + this.chartSettings.xLabelHeight + this.chartSettings.yAxisLabelHeight;
      return this.chartHeight;
    },
    getDeltaX() {
      const delta = (0 - parseFloat(this.plot.viewport.x_min));
      return delta;
    },
    getY(y) {
      return this.barBottom - parseFloat(y)*this.chartSettings.yAxisGap;
    },
    getX(x) {
      const left = this.chartSettings.yLabelWidth + (this.getDeltaX() + parseFloat(x))*this.chartSettings.xAxisGap; //(this.getViewPortWidth()/this.getSumMinMax());
      return left;
    },
    getHeight(bar) {
      return bar.y;
    },
    getFillStyle(el) {
      return "fill: " + el.color + "; ";
    },
    getStrokeStyle(el) {
      return "stroke: " + el.color + "; fill: none; ";
    },
    getStrokeFillStyle(el) {
      return "stroke: " + el.color + "; fill: " + el.color + "; ";
    },
    getPolylinePoints(data) {
      let str = "";
      data.forEach(point => {
        str += this.getX(point.x) + "," + this.getY(point.y) + " "
      });
      return str;
    },
    getPolygonPoints(data) {
      let str = "";
      data.forEach(point => {
        str += this.getX(point.x) + ", " + this.getY(point.y1) + " ";
      });
      for (let i = data.length-1; i >= 0; i--) {
        str += this.getX(data[i].x) + ", " + this.getY(data[i].y2) + " ";
      }
      return str;
    },
    setTooltip(ev, el) {
      if (el.tooltip) {
        this.tooltip.text = el.tooltip;
        this.tooltip.x = ev.clientX - this.$refs.domContainerChart.getBoundingClientRect().x - this.$refs.domChartTooltip.clientWidth - 16;
        this.tooltip.y = ev.clientY - this.$refs.domContainerChart.getBoundingClientRect().y - this.$refs.domChartTooltip.clientHeight - 16;
        this.tooltip.valueX = el.x;
        this.tooltip.valueY = el.y;
        this.tooltip.show = true;
      }
    },
    unsetTooltip() {
      this.tooltip.show = false;
    },
    svgClicked(ev) {
      if (ev.target.nodeName == 'svg') this.unsetTooltip();
    }    
  }

};
</script>