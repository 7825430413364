<template>
    <div class="overlay" :class="{ 'is-active': active, 'is-visible': visible }">
        <div class="lightbox" :style="style" :class="{ 'is--active': active, 'is--visible': visible }" @click.self="handleWrapperClick">
          <button v-if="!options.modal" class="button--icon button--close" @click="handleCloseClick">
            <i class="svgicon svgicon--close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
              </svg>
            </i>
          </button>
          <div class="lightbox__inner">
            <div class="ligthbox__content" >
              <figure class="image image--lighbtox" @click="galleryNext" :class="{ 'slidenext': isSlidenext, 'slideprev': isSlideprev, 'next-slidenext': isNextSlidenext, 'next-slideprev': isNextSlideprev, 'has-transition': hasTransition }">
                <img v-if="currentImage" :src="getImageSource(currentImage)" :alt="currentImage.title" :title="currentImage.title" @load="imageLoaded"/>
                <figcaption>{{ currentImage.title }}</figcaption>
              </figure>
            </div>
          </div>
          <button class="button--icon button--prev button--gallery" @click="galleryPrev" v-if="isGallery">
            <i class="svgicon svgicon--arrow" v-html="iconArrow"></i>    
          </button>
          <button class="button--icon button--next button--gallery" @click="galleryNext" v-if="isGallery">
            <i class="svgicon svgicon--arrow" v-html="iconArrow"></i>    
          </button>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from "vuex";
//import Store from "@/store";
//import axios from "axios";
//import qs from "qs";

//import { Api } from "@/api/";
import { ImageController } from "@/controller";

export default {
  name: "Lightbox",
  data() {
    return {
      visible: false,
      active: false,
      loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
      iconArrow: "<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21.6666 9.66634H5.43992L12.8933 2.21301L10.9999 0.333008L0.333252 10.9997L10.9999 21.6663L12.8799 19.7863L5.43992 12.333H21.6666V9.66634Z' fill='white' /></svg>",
      options: {
        image: {},
        gallery: []
      },
      index: -1,
      currentImage: {src: "", title: ""},
      isSlidenext: false,
      isSlideprev: false,
      isNextSlidenext: false,
      isNextSlideprev: false,
      hasTransition: false
    };
  },
  computed: {
    style() {
      return this.active ? "display: block;" : "display: none";
    },
    isGallery() {
      console.log('isGallery:', this.options.gallery.length);
      return this.options.gallery.length > 0;
    },
    /* currentImage() {
      return (this.options.gallery.length ? this.options.gallery[this.index] : this.options.image ) || {src: "", title: ""};
    }, */
  },
  properties: {
    title: {
      type: String,
      default: ""
    },
    bodytext: {
      type: String,
      default: ""
    }
  },
  watch: {},
  mounted() {},
  methods: {
    initShow() {
      if (this.options.gallery.length>0) {
        this.index = this.options.gallery.findIndex(item => item.url == this.options.image.url);
      }
      this.currentImage = (this.index>-1 ? this.options.gallery[this.index] : this.options.image);
    },
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },      
    handleWrapperClick() {
      if (!this.options.modal) {
        this.doClose();
      }
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
      this.close();
    },
    close() {
      this.$lightbox.close();
    },
    galleryPrev() {
      if (!this.moving) {
        this.index--;
        if (this.index<0) {
          this.index = this.options.gallery.length -1;
        }
        this.hasTransition = true;
        this.isSlideprev = true;
        this.updateImage();
      }
    },
    galleryNext() {
      if (!this.moving) {
        this.index++;
        if (this.index>=this.options.gallery.length) {
          this.index = 0;
        }
        this.hasTransition = true;
        this.isSlidenext = true;
        this.updateImage();
      }
    },
    updateImage() {
      this.moving = true;
      setTimeout(() => {
          this.currentImage = (this.index>-1 ? this.options.gallery[this.index] : this.options.image);
      }, 300);
    },
    imageLoaded() {
      const next = this.isSlidenext;
      const prev = this.isSlideprev;
      this.hasTransition = false;
      this.isNextSlidenext = next;
      this.isNextSlideprev = prev;
      //remove the slideout classes
      this.isSlidenext = this.isSlideprev = false;
      setTimeout(() => {
        this.hasTransition = true;
        setTimeout(() => {
          this.isNextSlidenext = this.isNextSlideprev = false;
          setTimeout(() => { 
            this.hasTransition = false;
            this.moving = false;
          }, 300);
        }, 5);
      }, 5);
    },
  }
};
</script>
