<template>
  <div class="content">
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "content",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["gSize", "gUser"]),
  },
  props: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  }
};
</script>
