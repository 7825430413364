<template>
  <div class="container-chart container-numbered-items" ref="domContainerChart" :class="{ 'has-oversize': hasOversize }">
    <div class="numbered-items-title">
      <i class="svgicon svgicon--tree">
          <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5004 11.3285C12.1804 10.9604 11.8441 10.5922 11.5241 10.2247C10.7723 9.39287 10.036 8.57658 9.41229 7.7129H10.6923C10.9642 7.7129 11.2042 7.5529 11.3004 7.3129C11.4123 7.0729 11.3642 6.78478 11.1885 6.5929C10.9485 6.30478 10.6923 6.0329 10.4367 5.76103C9.38042 4.59228 8.37235 3.48855 7.84419 2.08039L7.42794 0.960391C7.29981 0.608519 6.97981 0.400391 6.61168 0.400391C6.24418 0.400391 5.92418 0.624135 5.79605 0.960391L5.37979 2.08039C4.85229 3.48852 3.84416 4.59223 2.78859 5.76039C2.53234 6.03226 2.29234 6.32039 2.03672 6.59226C1.86047 6.78413 1.81298 7.07226 1.92485 7.31226C2.03672 7.55226 2.27672 7.71226 2.53297 7.71226H3.81297C3.18923 8.57601 2.45297 9.39226 1.70113 10.2241C1.36488 10.5922 1.04488 10.9441 0.724878 11.3278C0.533006 11.536 0.484878 11.856 0.613003 12.1116C0.724878 12.3678 0.981131 12.5435 1.26926 12.5435H5.41294V14.4316C5.41294 15.0879 5.94107 15.6154 6.59668 15.6154C7.25294 15.6154 7.78043 15.0872 7.78043 14.4316V12.5284H11.9241C12.2122 12.5284 12.4679 12.3684 12.5804 12.0966C12.7241 11.8403 12.676 11.5366 12.5004 11.3284L12.5004 11.3285Z" :fill="itemColor"/>
          </svg>
        </i>
        <p :style="titleStyle">{{ item.title }}</p>
    </div>
    <ul class="numbered-item-group"
      v-for="(grp,idx) in splits"
      :key="'numberedListSplitGroup_' + idx"
      :style="listStyle"
    >
      <li 
        v-for="(el, elIdx) in grp"
        :key="'numberedItem_' + idx + '_' + elIdx"
      >
        <i class="svgicon svgicon--tree">
          <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5004 11.3285C12.1804 10.9604 11.8441 10.5922 11.5241 10.2247C10.7723 9.39287 10.036 8.57658 9.41229 7.7129H10.6923C10.9642 7.7129 11.2042 7.5529 11.3004 7.3129C11.4123 7.0729 11.3642 6.78478 11.1885 6.5929C10.9485 6.30478 10.6923 6.0329 10.4367 5.76103C9.38042 4.59228 8.37235 3.48855 7.84419 2.08039L7.42794 0.960391C7.29981 0.608519 6.97981 0.400391 6.61168 0.400391C6.24418 0.400391 5.92418 0.624135 5.79605 0.960391L5.37979 2.08039C4.85229 3.48852 3.84416 4.59223 2.78859 5.76039C2.53234 6.03226 2.29234 6.32039 2.03672 6.59226C1.86047 6.78413 1.81298 7.07226 1.92485 7.31226C2.03672 7.55226 2.27672 7.71226 2.53297 7.71226H3.81297C3.18923 8.57601 2.45297 9.39226 1.70113 10.2241C1.36488 10.5922 1.04488 10.9441 0.724878 11.3278C0.533006 11.536 0.484878 11.856 0.613003 12.1116C0.724878 12.3678 0.981131 12.5435 1.26926 12.5435H5.41294V14.4316C5.41294 15.0879 5.94107 15.6154 6.59668 15.6154C7.25294 15.6154 7.78043 15.0872 7.78043 14.4316V12.5284H11.9241C12.2122 12.5284 12.4679 12.3684 12.5804 12.0966C12.7241 11.8403 12.676 11.5366 12.5004 11.3284L12.5004 11.3285Z" :fill="itemColor"/>
          </svg>
        </i>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "NumberedItems",
  components: {
  },
  data() {
    return {
      settings: {
        split: 100,
      },
      splits: []
    }
  },  
  computed: {
    itemColor() {
      return (this.item ? this.item.color : "#fff");
    },
    titleStyle() {
      return "color: " + this.itemColor + ";";
    },
    listStyle() {
      return "border-color: " + this.itemColor + ";";
    },
    hasOversize() {
      return this.index == 0 && this.item.trees > 500 ? true : this.index == 1 && this.item.trees > 100 ? true : false; 
    }
  },
  watch: {
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: -1
    }
  },
  created() {
  },
  mounted() {
    this.setSplits();
  },
  methods: {
    setSplits() {
      this.splits = [];
      const maxTrees = this.index == 0 && this.item.trees > 500 ? 500 : this.index == 1 && this.item.trees > 100 ? 100 : this.item.trees;
      const entries = Math.ceil(maxTrees / this.settings.split);
      //console.log('entries', entries, this.item.trees);
      let done = 0;
      for (var i = 0; i < entries; i++) {
        if ((maxTrees - done) >= this.settings.split) {
          this.splits.push(this.settings.split);
        } else {
          this.splits.push(maxTrees - done);
        }
        done += this.settings.split;
      }
      //console.log('got splits', this.splits, done);
    },
  }

};
</script>

