<template>
  <div class="container-back container-back--service">
    <button class="button--icon" @click="handleButtonClick">
      <i class="svgicon svgicon--arrow">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.6666 9.66634H5.43992L12.8933 2.21301L10.9999 0.333008L0.333252 10.9997L10.9999 21.6663L12.8799 19.7863L5.43992 12.333H21.6666V9.66634Z" fill="white"/></svg>
      </i>
    </button>
  </div>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "ServiceButtonBack",
  computed: {
    activeFormStep() {
      return this.$store.state.app.activeFormStep;
    },
    displayResult() {
      return this.$store.state.app.displayResult;
    }
  },
  props: {
  },
  watch: {
  },
  created() {
    
  },
  methods: {
    handleButtonClick() {
      if (this.$route.meta.displayBackButton) {
        this.$router.push({ path: "/" });
      } else {
        EventBus.$emit("onHideGdpr");
      }
    }
  }
};
</script>
