import { EventBus } from "@/bus/eventbus.js";

let resizeTimeOut = false, 
    resizeDelta = 200, 
    resizeTime = new Date(1, 1, 2000, 12, 0, 0);

const initResize = () => {
  window.removeEventListener("resize", listener);
  window.addEventListener("resize", listener);
    //initial resize
  checkResize();
}

const listener = () => {
  resizeTime = new Date();
  if (resizeTimeOut === false) {
    resizeTimeOut = true;
    setTimeout(function() {
      checkResize();
    }, resizeDelta);
  }
}

const checkResize = () => {
  if (new Date() - resizeTime < resizeDelta) {
    setTimeout(function() {
      checkResize();
    }, resizeDelta);
    return;
  }
  resizeTimeOut = false;
  EventBus.$emit("doResize");
}

export default initResize;