import Vue from "vue";
import infopageVue from "./Infopage.vue";

const InfopageConstructor = Vue.extend(infopageVue);

let instance;

const initInstance = () => {
  instance = new InfopageConstructor({
    el: document.createElement("div")
  });
  document.body.appendChild(instance.$el);
  document.body.addEventListener('click', (ev) => {
    const tp = document.querySelector('.infopage');
    if (tp) {
      if (!ev.path.filter(p => p && ( p.classList && p.classList.contains('infopage') )).length) {
        Infopage.close();
      }
    }
  });  
};

const showInfopage = (options) => {
  if (!instance) {
    initInstance();
  }

  document.body.appendChild(instance.$el);
  //const wrapper = document.querySelector('.app-wrapper');
  //if (wrapper) wrapper.classList.add('has-popup');
  instance.options = options;
  //Vue.nextTick(() => {
  instance.active = true;
  Vue.nextTick(() => {
    instance.initShow();
    setTimeout( () => { instance.visible = true; }, 50);
  });
  //});
};

const Infopage = function(options) {
  if (Vue.prototype.$isServer) return;
  showInfopage(options);
};

Infopage.show = options => {
  console.log('Infopage.show options', options);
  return Infopage(options);
};

Infopage.close = () => {
  instance.visible = false;
    setTimeout(() => {
      instance.active = false;
    }, 100);
  //const wrapper = document.querySelector('.app-wrapper');
  //if (wrapper) wrapper.classList.remove('has-popup');
};

export default Infopage;
export { Infopage };