<template>
  <div class="resultfilter">
    <div class="resultfilter__items">
      <div 
        v-for="(group, idx) in filterData"
        :key="'filterGroup' + idx"
        class="resultfilter__group-item"
      >
        <div
          v-for="(filter, subIdx) in group"
          :key="filter.uniqeId"
          class="resultfilter__item"
        >
          <button 
          :data-filter="filter.enum"
          class="button--filter"
          :class="{ 'is-selected': (selected[idx][subIdx] ? true : false) }"
          @click="onFilterClicked(filter, idx, subIdx)"
          >{{ filter.translation }}</button>
        </div>
      </div>
    </div>
    <div class="resultfilter__help" v-if="displayResult && filterData.length">
      <button
        class="button--help"
        @click="onToggleHelp"
    >
      <i class="svgicon svgicon--help" v-show="!filterHelpActive">
        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.33301 11.0002H7.66634V9.66683H6.33301V11.0002ZM6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM6.99967 12.3335C4.05967 12.3335 1.66634 9.94016 1.66634 7.00016C1.66634 4.06016 4.05967 1.66683 6.99967 1.66683C9.93967 1.66683 12.333 4.06016 12.333 7.00016C12.333 9.94016 9.93967 12.3335 6.99967 12.3335ZM6.99967 3.00016C5.52634 3.00016 4.33301 4.1935 4.33301 5.66683H5.66634C5.66634 4.9335 6.26634 4.3335 6.99967 4.3335C7.73301 4.3335 8.33301 4.9335 8.33301 5.66683C8.33301 7.00016 6.33301 6.8335 6.33301 9.00016H7.66634C7.66634 7.50016 9.66634 7.3335 9.66634 5.66683C9.66634 4.1935 8.47301 3.00016 6.99967 3.00016Z" fill="#8197AC"/>
        </svg>
      </i>
      <i class="svgicon svgicon--close" v-show="filterHelpActive">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
        </svg>            
      </i>      
    </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { dataMainnav } from "@/data";
import { ConfiguratorResultController } from "@/controller";
import { EventBus }from "@/bus/eventbus.js";

export default {
  components: {
  },
  name: "Resultfilter",
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },
    dataMainnav() {
      return dataMainnav;
    },    
    activeState() {
      return 1;
    },
    resultsFetched() {
      return this.$store.state.app.resultsFetched;
    }
  },
  data() {
    return {
      filterData: [],
      filterHelpActive: false,
      selected: []
    }
  },  
  props: {
    displayResult: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    displayResult() {
    },
    /* resultsFetched() {
      console.log('results fetched', this.resultsFetched);
      this.getResultFilterData();
    }, */
  },
  created() {
    EventBus.$on("onConfigurationFormResponseReceived", response => {
      //console.log('Resultfilter:onConfigurationFormResponseReceived:filter,filter_groups', response.data.filter, response.data.filter_groups);
      ConfiguratorResultController.setFilters(response.data.filter_groups);
      //console.log('ResultFilter -> onConfigurationFormResponseReceived', ConfiguratorResultController.filters);
      this.filterData = ConfiguratorResultController.filterItems; //response.data.filter;
        //reactivity
      this.filterData.forEach((group, idx) => {
        Vue.set(this.selected, idx, []);
        group.forEach((item, subIdx) => Vue.set(this.selected[idx], subIdx, false));
      });
      //console.log("Resultfilter:created:this.selected", this.selected);
    });
    EventBus.$on("onBeforeUpdateResult", () => {
      ConfiguratorResultController.resetFilters();
      this.filterData = [];
    });    
    //this.getResultFilterData();
  },
  methods: {
    /* async getResultFilterData() {
      this.filterData = await ConfiguratorResultController.getFilterData();
    }, */
    onFilterClicked(filter, idx, subIdx) {
      console.log("Resultfilter:onFilterClicked:this.filterData", this.filterData);
        //set all to false
      this.filterData[idx].forEach((filter, i) => {
        if (i == subIdx && !filter.selected) {
          filter.selected = true;
        } else {
          filter.selected = false;
        }
      });
        //update the selected
      this.selected[idx].forEach((item, i) => {
          //reactivity
        Vue.set(this.selected[idx], i, (i == subIdx ? (this.selected[idx][i] ? false : true) : false));
      });
      //ConfiguratorResultController.updateFilters(this.selected);
        //set only the current one to selected
      //filter.selected = !filter.selected;
      EventBus.$emit("onResultFilterChanged");
    },
    onToggleHelp() {
      //console.log('toggle help');
      this.filterHelpActive = !this.filterHelpActive;
      EventBus.$emit("onToggleHelp", "filter");
    }
  }
};
</script>
