<template>
  <Header class="header-main" :class="{ 'service-content': displayGdpr }">
    <div class="logo"> 
      <a href="/" title="iDM Konfigurator" @click.prevent="onLogoClick">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70 0H0V70H70V0Z" fill="black"/>
          <path d="M58.1717 52.4771L59.1882 55.1055C61.175 52.7999 62.6074 49.6641 62.6074 45.3756C62.6074 30.4349 54.8912 26.6997 43.5249 26.6997H42.2311H27.5381V61.2847H45.6041C45.6041 61.2847 49.9935 61.008 54.2906 58.8407L55.3995 52.4309H58.1717V52.4771Z" fill="white"/>
          <path d="M65.6569 52.4771H62.7922L60.4358 58.3796L59.1883 55.1516C57.7559 56.8117 56.0002 58.0107 54.2906 58.8868L53.5051 63.4981H56.3698L57.2939 57.1806H57.3401L59.8814 63.4981H61.0365L63.6701 57.1806H63.7163L64.5018 63.4981H67.3665L65.6569 52.4771Z" fill="white"/>
          <path d="M59.1883 55.1514L58.1718 52.5229H55.3533L54.2444 58.9327C56.0464 58.0104 57.7559 56.8115 59.1883 55.1514Z" fill="black"/>
          <path d="M24.9042 26.7456H7.30029V61.3306H24.9042V26.7456Z" fill="#FFF042"/>
          <path d="M40.7063 37.6743H42.4159C46.7129 37.6743 49.2542 39.98 49.2542 44.2224C49.2542 48.6954 46.4357 51.0011 42.2773 51.0011H42.1386H40.7063V37.6743Z" fill="black"/>
          <path d="M25.8283 15.494C25.8283 20.4743 21.4851 24.4861 16.1253 24.4861C10.7656 24.4861 6.42236 20.4743 6.42236 15.494C6.42236 10.5138 10.7656 6.50195 16.1253 6.50195C21.4851 6.50195 25.8283 10.5138 25.8283 15.494Z" fill="#FFF042"/>
        </svg>
      </a>
    </div>
    <ButtonBack :class="{ 'is-hidden': !showDetail }" v-if="!displayGdpr"/>
    <ServiceButtonBack v-if="displayGdpr || $route.meta.displayBackButton" />
    <!-- <div v-if="false" class="dropdown--project" :class="{ 'is-active': mode=='expert' && gDisplayResult }" >
      <Dropdown :items="dataProjects" />
    </div> -->
    <MobileHelpToggle :class="{ 'is-active': gDisplayResult && !showDetail }" />
    <!-- <ExpertNav v-if="mode=='expert' && !gDisplayResult" /> -->
    <div class="wrapper-nav" :class="{ 'is-active': gDisplayResult }">
      <Resultfilter :class="{ 'is-active': gDisplayResult && !showDetail && !displayGdpr }" :displayResult="gDisplayResult" />
      <ResultviewNav :class="{ 'is-active': gDisplayResult && showDetail && !displayGdpr }" />
    </div>
    <div class="login-service" :class="{ 'is-partner': mode=='expert'}">
      <div class="login">
        <button class="button--dense" @click="handleLoginClick" :title="loginButtonTitle">
          <span>{{ loginButtonTitle }}</span>
          <i class="svgicon svgicon--login">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z" fill="white"/>
            </svg>
          </i>
        </button>
      </div>
      <div class="service-nav-trigger">
        <button class="button--icon" @click="handleServiceNavClicked" title="Service">
          <i class="svgicon svgicon--menusmall">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="#CDDDEC" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </i>
        </button>   
        <nav class="service-nav" :class="{ 'is-active': serviceNavActive, 'has-transition': serviceNavTransition, }" :style="serviceNavStyle" @transitionend="onServiceNavTransitionEnd">
          <ul class="service-nav-list" ref="domServiceNavList">
            <li><a href="https://www.idm-energie.at" target="_blank" @click="handleServiceNavClicked">idm-energie.at</a></li>
            <li><a href="https://www.idm-energie.at/impressum/" target="_blank" @click="handleServiceNavClicked">{{ getContent('TEXT_IMPRINT', true) }}</a></li>
            <li><a href="https://www.idm-energie.at/datenschutz/" target="_blank" @click.prevent="handleGdprClicked">{{ getContent('TEXT_DATA_PROTECTION', true) }}</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </Header>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";
import { mapGetters } from "vuex";
import { LoginController, ConfiguratorFormController, ContentController } from "@/controller";
//import ExpertNav from "./Expertnav.vue";
import ResultviewNav from "./ResultviewNav.vue";
import Resultfilter from "./Resultfilter.vue";
import ButtonBack from "./Back.vue";
import ServiceButtonBack from "./ServiceBack.vue";
//import { Dropdown } from "@/components";
import MobileHelpToggle from "./MobileHelpToggle.vue";

export default {
  name: "HeaderMain",
  components: {
    //ExpertNav,
    Resultfilter,
    //Dropdown,
    ResultviewNav,
    ButtonBack,
    ServiceButtonBack,
    MobileHelpToggle
  },
  computed: {
    ...mapGetters(["gDisplayResult"]),
    mode() {
      return this.$store.state.app.mode;
    },
    showDetail() {
      return this.$store.state.app.showDetail;
    },
    isServicePage() {
      //console.log("Header:isServicePage", this.$route.name == "gdpr");
      return this.$route.name == "gdpr";
    },
    loginButtonTitle() {
      return this.mode=='expert'?LoginController.user.userRealname:this.getContent("CONF_TEXT_LOGIN_BUTTON", true);
    },
  },
  data() {
    return {
      serviceNavActive: false,
      serviceNavStyle: "",
      serviceNavTransition: false,
      displayGdpr: false,
    }
  },
  created() {
    EventBus.$on("onShowGdpr", () => {
      this.displayGdpr = true;
    }); 
    EventBus.$on("onHideGdpr", () => {
      this.displayGdpr = false;
    });        
  },
  methods: {
    handleLoginClick() {
      if (this.mode == "expert") {
        LoginController.doLogout();
      } else {
        this.$loginForm.show();
      }
    },
    onLogoClick() {
      this.$dialog({
        title: "Konfigurator neu laden", 
        text: "Es werden alle Formulardaten gelöscht. Fortfahren?",
        modal: true,
        onOk: () => {
          ConfiguratorFormController.removeFormDataFromLocalStorage();
          window.location = "/";
        }
      });
    },
    handleServiceNavClicked() {
      if (this.serviceNavActive) {
        this.collapseServiceNav();
      } else {
        this.expandServiceNav();
      }
    },
    handleGdprClicked() {
      EventBus.$emit("onShowGdpr");
      if (this.serviceNavActive) {
        this.collapseServiceNav();
      } else {
        this.expandServiceNav();
      }
    },
    collapseServiceNav() {
        this.serviceNavStyle = "height: " + this.$refs.domServiceNavList.getBoundingClientRect().height + "px;";
        this.serviceNavTransition = true;
        this.$nextTick(() => {
          this.serviceNavStyle = "height: 0;";
          this.serviceNavActive = false;
        });
    },
    expandServiceNav() {
        this.style = "height: 0;";
        this.serviceNavTransition = true;
        this.serviceNavActive = true;
        this.$nextTick(() => {
          this.serviceNavStyle = "height: " + this.$refs.domServiceNavList.getBoundingClientRect().height + "px;";
        });
    },  
    onServiceNavTransitionEnd() {
      this.serviceNavTransition = false;
    },  
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }    
  },
};
</script>
