import { ConfiguratorResultDataService} from "@/dataservice";
import Store from "@/store";
import Uniqe from "@/utils/Uniqe.js";
//import { isArray } from "core-js/core/array";

const ConfiguratorResultController = {
    items: [],
    pages: [],
    filterItems: [],
    systemHelp: [],
    message: "",

    async getData() {
        Store.dispatch("setResultsFetched", false);
        //this.items = this.addUniqeIdToItems(this.convertNAToObject(await this.fetchData()));
        this.items = this.addUniqeIdToItems(await this.fetchData());
        Store.dispatch("setResultsFetched", true);
        //console.log('ConfiguratorResultController.getData', this.items);
        return this.items;
    },

    async fetchData() {
        const data = await ConfiguratorResultDataService.fetchResultData();
        console.log('ConfiguratorResultController fetchData', data);
        return data;
    },

    async getFilterData() {
        this.filterItems = this.addStateToFilter(await this.fetchFilterData());
        return this.filterItems;        
    },

    async fetchFilterData() {
        const data = await ConfiguratorResultDataService.fetchFilterData();
        console.log('ConfiguratorResultController.fetchData -> data', data);
        return data;
    },

    setItems(data) {
        this.items = this.addUniqeIdToItems(data);
        console.log('ConfiguratorResultController -> setItems', this.items);
    },

    setMessage(data) {
        this.message = data.message;
        console.log('ConfiguratorResultController -> setMessage', this.message);
    },

    setFilters(data) {
        //console.log('ConfiguratorResultcontroller->setFilters', data);
        this.filterItems = this.addUniqeIdToItems(data);
    },

    setSystemHelp(data) {
        //console.log('setSystemHelp', data.system_help);
        this.systemHelp = [];
        if (data && data.system_help) data.system_help.forEach(help => this.systemHelp.push(help));
        /* data.forEach(item => item.system_help.forEach(help => {
            if (this.systemHelp.indexOf('help.title'). this.systemHelp.push(help);
        })); */
    },

    setPages(data) {
        this.pages = this.addUniqeIdToItems(data);
    },

    resetItems() {
        this.items = [];
    },

    resetFilters() {
        this.filterItems = [];
    },

    updateFilters(selected) {
        this.filterItems.forEach((group, idx) => {
            group.forEach((filter, subIdx) => this.filterItems[idx][subIdx].selected = (selected[idx][subIdx]));
        });
        console.log("ConfiguratorResultContainer:updateFilter:filterItems", this.filterItems);
    },

    getCentPerKwh() {
        let result = 0;
        if (this.pages) {
            this.pages.forEach(page => {
                if (page.summary) {
                    page.summary.forEach(summary => {
                        if (summary.variable && summary.variable == "CENT_PER_KWH") result = summary.calculated;
                    });
                }
            });
        }
        return result;
    },
    
    /**
     * these 2 funcs create filterdata based on the tags
     */

    /* getFilterData() {
        let filterData = [];
        if (this.items) {
            this.items.forEach(item => {
                item.summary.forEach(summary => {
                    console.log('getFilterData', summary);
                    filterData = this.filterTags(summary, filterData);
                });
            });
        }
        console.log("ConfiguratorResultController[getFilterData] -> filterData", filterData);
        return filterData;
    },

    filterTags(item, filterData) {
        if (item.subcards) {
            item.subcards.forEach(card => {
                filterData = this.filterTags(card, filterData);
            });
        }
        console.log('filterTags', filterData, item.tags);
        return [...filterData, ...item.tags.filter(tag => filterData.indexOf(tag) == -1)];
    },*/

    getFilteredItems() {
        if (this.items && this.filterItems) {
            const setFilters = [];
            this.filterItems.forEach(group => group.forEach(f => {
                if (f.selected) setFilters.push(f);
            }));
            if (setFilters.length) {
                const items = this.items.filter(item => setFilters.every(setFilter => item.filter.includes(setFilter.enum)));
                return items;
            } else {
                return this.items;
            }
        } else {
            return this.items;
        }
    },

    /* itemInFilter(tags, filters) {
        return tags.some(tag => filters.some(f => f.enum == tag));
    }, */
    /* addStateToFilter(items) {
        items.forEach(item => {
            item.selected = false;
        });
        return items;
    }, */

    /* convertNAToObject(items) {
        items.forEach(item => {
            item.pages[0].summary.forEach(cItem => {
                if (typeof cItem !== "object") {
                    cItem = {}
                    cItem.headline = "Hier könnte Ihr Text stehen";
                    cItem.subline = "weil da nix steht";
                    console.log('item.pages[0].summary', item.pages[0].summary);
                }
            });
        });
        return items;
    }, */

    addUniqeIdToItems(items) {
        if (items) {
            items.forEach(item => {
                if (Array.isArray(item)) {
                    item = this.addUniqeIdToItems(item);
                } else {
                    item.uniqeId = Uniqe.getUniqe();
                    if (item.pages) {
                        item.pages.forEach(page => page.summary.forEach((sItem, idx) => {
                            if (sItem.variable || sItem.formula) {
                                sItem.calculated = sItem.default;
                            }
                                //check if item is a "n/a" value
                            if (typeof sItem !== "object" && sItem == "n/a") {
                                sItem = {
                                    "headline": "Gerät",
                                    "subline": "nich benötigt"
                                }
                                    //set this new
                                page.summary[idx] = sItem;
                            }
                            sItem.uniqeId = Uniqe.getUniqe()
                        }));
                    };
                    if (item.focus_text) item.focus_text.forEach(fItem => fItem.uniqeId = Uniqe.getUniqe());
                    if (typeof item.enum !== "undefined") item.selected = false;
                }
            });
        }
        return items;
    },
};
  
export default ConfiguratorResultController;