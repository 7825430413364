import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/prototype/classes.js";
import InlineSvg from 'vue-inline-svg';
import VueMeta from 'vue-meta'

import "./permission.js";
import "@/assets/scss/app.scss";

//Vue.use(VueMaterial);
Vue.component('inline-svg', InlineSvg);
Vue.use(VueMeta)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
