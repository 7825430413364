<template>
  <div class="container-chart container-stacked-bar" ref="domContainerBar" >
    <h4 class="chart-title">{{ plot.title }}</h4>
    <div class="chart-summary">
      <ul class="summary-items" :data-items="visibleItems">
        <li
          v-for="(bar,idx) in plot.data"
          v-bind:key="'summaryItem'+idx"
        >
          <span class="summary-dot" :style="getBackgroundStyle(bar)"></span>
          <span class="summary-title">{{ bar.name }}</span>
        </li>
      </ul>
    </div>
    <div class="stacked-bar" :data-items="visibleItems" v-if="!isPartner">
      <div class=""
          v-for="(bar,idx) in plot.data"
          v-bind:key="'stackedBarItem'+idx"
          :style="getBarStyle(bar)"
      >
      </div>
    </div>
    <div class="stacked-values">
      <ul class="stacked-value-items" :data-items="visibleItems">
        <li
          v-for="(bar,idx) in plot.data"
          v-bind:key="'summaryItem'+idx"
        >
          <span :style="getColorStyle(bar)">{{ bar.value }} %</span>
          <span class="summary-value" :style="getColorStyle(bar)">{{ bar.title_value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"

export default {
  name: "StackedBar",
  components: {
  },
  data() {
    return {
      chartSettings: {
        width: 200,
        height: 200,
      },
    }
  },  
  computed: {
    barBottom() {
      return this.viewbox.height - this.chartSettings.xLabelHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    visibleItems() {
      //console.log('this.plot.data', this.plot.data);
      return this.plot.data; //this.plot.data.filter(d => d.value != 0).length;
    }
  },
  watch: {
  },
  props: {
    plot: {
      type: Object,
      default: null
    },
    isPartner: {
      type: Boolean,
      default: false
    },      
  },
  created() {
  },
  methods: {
    getBackgroundStyle(bar) {
      return "background-color: " + bar.color + ";";
    },
    getColorStyle(bar) {
      return "color: " + bar.color + ";";
    },
    getBarStyle(bar) {
      return "background-color: " + bar.color + "; flex: 0 0 " + bar.value + "%;";
    }
  }

};
</script>