//import Cookies from "js-cookie";

const app = {
  state: {
    device: "desktop",
    language: /* Cookies.get("language") || */ "de",
    size: {
      screen: {
        width: 0,
        height: 0
      },
    },
    mode: "customer",
    configuratorFormMode: "basic", //basic, expert
    activeFormStep: 0,
    formStepsDone: 0,
    editFormOnResult: false,
    googlePlacesLoaded: false,
    displayResult: false,
    startedOnMobile: false,
    resultsFetched: false,
    showDetail: "",
    detailPage: "",
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      //Cookies.set("language", language);
    },
    SET_SIZE: (state, size) => {
      state.size.screen.width = size.screen.width;
      state.size.screen.height = size.screen.height;
    },
    SET_ACTIVE_FORM_STEP: (state, step) => { 
      state.activeFormStep = step;
    },
    SET_FORM_STEPS_DONE: (state, value) => { 
      state.formStepsDone = value;
    },
    SET_EDIT_FORM_ON_RESULT: (state, value) => { 
      state.editFormOnResult = value;
    },    
    SET_MODE: (state, value) => {
      state.mode = value;
    },
    SET_CONFIGURATOR_FORM_MODE: (state, value) => {
      state.configuratorFormMode = value;
    },    
    SET_GOOGLE_PLACES_LOADED: (state, value) => {
      state.googlePlacesLoaded = value;
    },
    SET_DISPLAY_RESULT: (state, value) => {
      state.displayResult = value;
    },
    SET_STARTED_ON_MOBILE: (state, value) => {
      state.startedOnMobile = value;
    },
    SET_RESULTS_FETCHED: (state, value) => {
      state.resultsFetched = value;
    },
    SET_SHOW_DETAIL: (state, value) => {
      state.showDetail = value;
    },    
    SET_DETAIL_PAGE: (state, value) => {
      state.detailPage = value;
    },    
  },
  actions: {
    toggleDevice({ commit }, device) {
      commit("TOGGLE_DEVICE", device);
    },
    setLanguage({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
    setSize({ commit }, size) {
      commit("SET_SIZE", size);
    },
    setActiveFormStep({ commit }, value) {
      commit("SET_ACTIVE_FORM_STEP", value);
    },
    setFormStepsDone({ commit }, value) {
      commit("SET_FORM_STEPS_DONE", value);
    },
    setEditFormOnResult({ commit }, value) {
      commit("SET_EDIT_FORM_ON_RESULT", value);
    },
    setMode({ commit }, value) {
      commit("SET_MODE", value);
    },
    setConfiguratorFormMode({ commit }, value) {
      commit("SET_CONFIGURATOR_FORM_MODE", value);
    },    
    setGooglePlacesLoaded({ commit }, value) {
      commit("SET_GOOGLE_PLACES_LOADED", value);
    },
    setDisplayResult({ commit }, value) {
      commit("SET_DISPLAY_RESULT", value);
    },
    setResultsFetched({ commit }, value) {
      commit("SET_RESULTS_FETCHED", value);
    },
    setShowDetail({ commit }, value) {
      commit("SET_SHOW_DETAIL", value);
    },
    setDetailPage({ commit }, value) {
      commit("SET_DETAIL_PAGE", value);
    },
    setStartedOnMobile({ commit }, value) {
      commit("SET_STARTED_ON_MOBILE", value);
    }
  }
};

export default app;
