import { /* ConfiguratorFormDataService, */ RequestDataService } from "@/dataservice";
import { LoginController, ConfiguratorFormController /* , PdfController */ } from "@/controller";
import ConfiguratorResultController from "./ConfiguratorResultController";
//import Store from "@/store";
//import { EventBus } from "@/bus/eventbus.js";
//import PostData from "@/utils/PostData.js";
//import Uniqe from "@/utils/Uniqe.js";

const RequestController = {
    valueDefaults: {

    },

    item: {},
    organization: {},
    
    location: {
        country: "",
        lat: "",
        lng: "",
        address: ""
    },

    requestFormData: [ ],    

    requestFormValues: [
        {
          "name": "name",
          "value": ""
        },
        {
          "name": "email",
          "value": ""
        },
        {
          "name": "phone_number",
          "value": ""
        },
        {
          "name": "realization_planned_in_months",
          "value": 0
        },
        {
          "name": "notes",
          "value": ""
        }, 
        {
          "name": "accept_mail",
          "value": true
        }
    ],   

    getPdfValues() {
       const v = JSON.parse(JSON.stringify(this.requestFormValues));
       const field = this.requestFormData.find(e => e.name == "realization_planned_in_months");
       v.forEach(entry => {
        if (entry.name == "realization_planned_in_months") {
            let val;
            if (field) {
                const option = field.options.find(o => o.value == entry.value)
                if (option) {
                    val = option.text;
                } else {
                    val = "";
                }
            } else {
                val = "";
            }
            entry.value = (field?val:"");
        }
       });
       return v;
    },

    formSubmitted: false,

    setItem(item) {
        this.item = item;
    },

    getItem() {
        return this.item;
    },

    setPartner(organization) {
        this.organization = organization;
    },

    getPartner() {
        return this.organization;
    },

    setFormSubmitted(submitted) {
        this.formSubmitted = submitted;
    },

    getFormSubmitted() {
        return this.formSubmitted;
    },    

    setLocationData(country, lat, lng, address) {
        this.location.country = country.value?country.value:country;
        this.location.lat = lat.value?lat.value:lat;
        this.location.lng = lng.value?lng.value:lng;
        this.location.address = address.value?address.value:address;
    },

    getLocationData() {
        return this.location;
    },

    resetFormValues() {
        this.requestFormValues = [
            {
              "name": "name",
              "value": ""
            },
            {
              "name": "email",
              "value": ""
            },
            {
              "name": "phone_number",
              "value": ""
            },
            {
              "name": "realization_planned_in_months",
              "value": 0
            },
            {
              "name": "notes",
              "value": ""
            },
            {
              "name": "accept_gdpr",
              "value": false
            },
            {
              "name": "accept_mail",
              "value": true
            }
        ]         
    },

    async searchOrganizations(lat, lng, country) {
        const response = await RequestDataService.fetchOrganizations(lat, lng, country);
        return response;
    },

    async postRequest() {
        //console.log('ConfiguratorFormDataService.values', ConfiguratorFormDataService.values);
        //console.log('LoginController.token: ', LoginController.token);
        //this.storeFormDataToLocalStorage(ConfiguratorFormDataService.values);
        const postData = this.getRequestPostData(); //this.getFormPostData(this.requestFormValues);
        //postData["file"] = PdfController.pdf;
        /* await this.getBase64().then(fileData => {
            postData["file"] = fileData; 
            console.log('RequestController:getRequestPostData:result', postData);
        }); */
        
    
        const response = await RequestDataService.postRequest(postData, LoginController.token);
        if (response) {
            console.log('RequestController:postRequest:response', response);
            //EventBus.$emit("onConfigurationFormResponseReceived", response);               
            return response;
        }
    },

    getRequestPostData() {
        let data = {};
        data = this.getFormPostData(data);
        data["userrequestdata_set"] = ConfiguratorFormController.getPostDataForRequest();
        console.log("RequestController:getRequestPostData:userrequestdata_set", data["userrequestdata_set"]);
        return data;
    },

    getPostValue(item) {
        //console.log('ConfiguratorFormDataService.values', ConfiguratorFormDataService.values);
        //const entry = ConfiguratorFormDataService.values[step].filter(e => Object.keys(e)[0] == item.name); 
        const entry = this.getValueEntryByItemName(item.name);
        //console.log('RequestController:getPostValue:entry', entry);
        const val = entry?entry.value:"";
        //console.log('getFormValue val', item.name, val);
        return val;
    },

    getFormPostData(postData) {
        //console.log('ConfiguratorFormController->getFormPostData values', ConfiguratorFormDataService.values);
        //console.log('ConfiguratorFormController->getFormPostData fields', this.formFields);
        /** send in json format */
        //const result = {};
        this.requestFormValues.forEach(item => {
            postData[item.name] = item.value;
        });
        console.log("RequestController.getFormPostData:this.item.userrequest", this.item.userrequest);
        //postData["items"] = this.item.userrequest.items;
        postData["organization"] = this.organization.partner_number;
        postData["cent_per_kwh"] = ConfiguratorResultController.getCentPerKwh();
        //postData["scheme"] = this.item.userrequest.scheme;
        //postData["userrequest"] = this.item.userrequest;
        for(let prop in this.item.userrequest) {
            postData[prop] = this.item.userrequest[prop];
        }
        return postData;
        
        /** if data should be send like the configurator form use this */
        /* let fData = {};
        this.requestFormData.forEach(field => {            
            const postValue = this.getPostValue(field);
            if (postValue) {
                fData[field.name] = postValue;
            }
        });
        return PostData.build(fData); */
    },

    getValueEntryByItemName(itemName) {
        const entry = RequestController.requestFormValues.filter(v => v.name == itemName);
        /* let entry = [], i = 0;
        while (i < this.requestFormValues.length && entry.length == 0) {
            entry = this.requestFormValues[i].filter(e => Object.keys(e)[0] == itemName);
            i++;
        }         
        return entry; */
        if (entry && entry.length>0) {
            return entry[0];
        } else {
            return {}
        }
    },   
    
    async getBase64() {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
          };
          reader.onerror = error => reject(error);
        });
    }    

};
  
export default RequestController;