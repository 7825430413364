import { constantRouterMap } from "@/router";
import Layout from "@/views/layout/";
import ContentRegular from "@/views/content/";

/**
 * @param Array item
 */
function buildRouterItems(data, nav) {
  if (nav == "mainnav") {
    const res = [];
    data.forEach(item => {
      const path = item.link.replace("/backend", "");
      res.push(buildMainnavRoute(item, path, nav, 0));
    });
    return res;
  }
}

function getLayout(level) {
  if (level != 0) {
    return ContentRegular;
  } else {
    return Layout;
  }
}

function buildMainnavRoute(item, path, nav, level) {
  //console.log('buildMainnav route', path);
  const route = {
    path: path,
    component: getLayout(level),
    redirect: "index",
    meta: {
      title: item.title,
      dataType: item.data.tx_wwpackage_return_type,
      hasOverflow: item.data.tx_wwpackage_has_overflow,
      svgIcon: item.data.tx_wwxcontent_svg_icon,
      pageId: item.data.uid,
      noCache: true,
      type: nav,
      display: 1,
      rootPath: path,
      viewName: removeSlashFromPath(path),
      level: level
    },
    children: []
  };
  let nextLevel = level+1;

  route.children.push(buildChildPath(item, path, nav, nextLevel));
  if (item.children && item.children.length) {
    item.children.forEach(child => {
        const childPath = child.link.replace("/backend", "");
        //console.log('path, childPath', path, childPath);
        route.children.push(buildMainnavRoute(child, childPath, nav, nextLevel));
    });
  }
  //console.log('route', route);
  return route;
}

function buildChildPath(item, path, nav, level) {
  return {
    path: path,
    component: () => {
      return import("@/views/home/index");
    },
    name: path,
    meta: {
      title: item.data.title,
      dataType: item.data.tx_wwpackage_return_type,
      hasOverflow: item.data.tx_wwpackage_has_overflow,
      pageId: item.data.uid,
      noCache: true,
      type: nav,
      display: 1,
      rootPath: path,
      viewName: removeSlashFromPath(path),
      level: level
    }
  }
}

function removeSlashFromPath(path) {
  if (path.indexOf("/")===0) {
    path = path.substr(1,path.length);
  }
  while (path.indexOf("/")!=-1) {
    path = path.replace("/", "-");
  }
  return path;
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      if (state.addRouters.length) {
        state.addRouters = state.addRouters.concat(routers);
      } else {
        state.addRouters = routers;
      }
      state.routers = constantRouterMap.concat(state.addRouters);      
    },
    RESET_ROUTERS: state => {
      state.addRouters = [];
      state.routers = constantRouterMap;
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        let accessedRouters = [];
        accessedRouters = buildRouterItems(data.routes, data.nav);
        commit("SET_ROUTERS", accessedRouters, data.nav);
        resolve();
      });
    },
    ResetRoutes({ commit }) {
      commit("RESET_ROUTERS");
    }
  }
};

export default permission;
