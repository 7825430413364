import Vue from "vue";
import dialogVue from "./Dialog.vue";

const DialogConstructor = Vue.extend(dialogVue);

let instance;

const initInstance = () => {
  instance = new DialogConstructor({
    el: document.createElement("div")
  });
  document.body.appendChild(instance.$el);
};

const showDialog = (options) => {
  if (!instance) {
    initInstance();
  }
  console.log('showDialog options', options);
  document.body.appendChild(instance.$el);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.add('has-popup');
  instance.options = options;
  Vue.nextTick(() => {
    instance.active = true;
    Vue.nextTick(() => {
      instance.initShow();
      setTimeout( () => { instance.visible = true; }, 50);
    });
  });
};

const Dialog = function(options) {
  if (Vue.prototype.$isServer) return;
  showDialog(options);
};

Dialog.show = options => {
  return Dialog(options);
};

Dialog.close = () => {
    instance.visible = false;
      setTimeout(() => {
        instance.active = false;
      }, 300);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.remove('has-popup');
};

export default Dialog;
export { Dialog };