
import axios from "axios"; 
import { /* dataResult, */ dataResultFilter } from "@/data";
import { Api } from "../api";

const ConfiguratorResultDataService = {

    data: [],
    values: [],
    filters: [],

    async fetchResultData(/* mode */) {
        //TODO: 
        /* local version */
            //this.data = dataResult.data;
            //return this.data;
        
        const url = Api.BACKEND_URL + Api.EXAMPLE_RESULT;
        try {
          let response = await axios.create({ withCredentials: false }).get(url);
          if (response.status == 200) {
            this.data = response.data.data?response.data.data:response.data;
          }
        } catch (err) {
          console.error(err);
        }

        return this.data;

        /* try {
            let response = await axios.create().get(Api.BACKEND_URL + Api.RESULT);
            if (response.data) {
                console.log('ConfiguratorResultDataService[fetchResultData] response', response);
                this.data = response.data;
                return this.data;
            } else {
            return [];
            }
        } catch (err) {
            console.error(err);
        } */

    },

    async fetchFilterData(/* mode */) {
        //TODO: 
        this.filters = dataResultFilter;
        return this.filters;
    },

  };
  
  export default ConfiguratorResultDataService;