<template>
  <div class="tooltip" :class="{ 'is-active': active, 'is-visible': visible }" :style="style" ref="domTooltip">
    <button class="button--icon button--close-tooltip" @click="handleCloseClick">
        <i class="svgicon svgicon--close">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
            </svg>
        </i>
    </button>  
    <p class="tooltip-message">{{ options.message }}</p>
  </div>
</template>

<script>

export default {
  name: "Tooltip",
  data() {
    return {
      visible: false,
      active: false,
      options: {},
      style: "",
      pos: {
        top: 0,
        left: 0,
      }
    };
  },
  computed: {
  },
  watch: {},
  mounted() {},
  methods: {
    initShow() {
      if (this.options.el) {
        const scWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const pBounding = this.options.el.getBoundingClientRect();
        const toolBounding = this.$refs.domTooltip ? this.$refs.domTooltip.getBoundingClientRect() : null;
        const maxTop = pBounding.top - (toolBounding ? toolBounding.height : 0);
        this.pos.top = maxTop > 0 ? pBounding.top : pBounding.top - maxTop + 16; //add some padding to top if top is to low
        this.pos.left = pBounding.left - toolBounding.width / 2 < 0 ? toolBounding.width / 2 : (pBounding.left + toolBounding.width > scWidth ? scWidth / 2 : pBounding.left);
        this.style = "left: " + this.pos.left + "px; top: " + this.pos.top + "px; transform: translate(-50%, -105%);";
      }
    },
    resetStyle() {
      this.style = "left: " + this.pos.left + "px; top: " + this.pos.top + "px;";
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
      this.$tooltip.close();
    },
    getPageScroll() {
      var yScroll;
      if (window.pageYOffset) {
        yScroll = window.pageYOffset;
      } else if (document.documentElement && document.documentElement.scrollTop) {
        yScroll = document.documentElement.scrollTop;
      } else if (document.body) {
        yScroll = document.body.scrollTop;
      }
      return yScroll;
    },
  }
};
</script>
