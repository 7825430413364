<template>
  <div class="infopage" :class="{ 'is-active': active, 'is-visible': visible }" :style="style">
    <header class="infopage-header">
      <h3>{{ contentHeader }}</h3>
      <button class="button--icon" @click="handleCloseClick">
          <i class="svgicon svgicon--close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
              </svg>
          </i>
      </button>  
    </header>
    <main class="infopage-content">
      <div class="main-content">
        <div class="infopage-menu-content">
          <Infoitem
            v-for="(page, idx) in subpages"
            :key="'infoPageItem' + idx"
            :page="page"
            :pageIndex="idx"
            :activePageIndex="activePageIndex"
            @onClick="onPageMenuClick"
          >
          </Infoitem>   
        </div>
        <div class="infopage-content-only hide-on-mobile" :style="infoPageStyle">
          <div 
            v-for="(page, idx) in subpages"
            :key="'infopage' + idx"
            class="infopage-page"
            :data-index="idx"
            :class="{ 'is-active': idx == activePageIndex }"
            ref="domInfoPage"
          >
            <h4>{{ page.title }}</h4>
            <div class="page-text" v-html="page.text"></div>
            <div class="images">
              <figure 
                v-for="(image, imageIdx) in page.pictures"
                :key="'image_' + idx + '_' + imageIdx"
              >
                <img :src="getImageSource(image)" :title="page.title" :alt="page.title" />
              </figure>
            </div>            
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import { Infoitem } from "./components/";
import { ImageController } from "@/controller/";

export default {
  name: "Infopage",
  components: {
    Infoitem
  },
  data() {
    return {
      visible: false,
      active: false,
      options: {},
      style: "",
      pos: {
        top: 0,
        left: 0,
      },
      activePageIndex: 0,
      activeHeight: 0
    };
  },
  computed: {
    subpages() {
      return (this.options && this.options.content) ? this.options.content.sub_pages : [];
    },
    contentHeader() {
      return (this.options && this.options.content) ? this.options.content.header : "";
    },
    infoPageStyle() {
      return (this.activeHeight ? "height: " + this.activeHeight + "px;" : "");
    }
  },
  watch: {},
  mounted() {    
  },
  methods: {
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },        
    initShow() {
      console.log("Infopage:initShow:options", this.options);
      if (this.$refs.domInfoPage && this.$refs.domInfoPage[0]) {
        this.activeHeight = this.$refs.domInfoPage[0].getBoundingClientRect().height;
      }
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
      this.$infopage.close();
    },
    onPageMenuClick(pageItem, pageIndex) {
      this.activePageIndex = pageIndex;
      this.$nextTick(() => {
        if (this.$refs.domInfoPage && this.$refs.domInfoPage[pageIndex]) {
          this.activeHeight = this.$refs.domInfoPage[pageIndex].getBoundingClientRect().height;
        }
      });
    },
  }
};
</script>
