<template>
  <div class="svg-doughnut" ref="domSvgDoughnut" >
    <svg :width="chartSettings.size" :height="chartSettings.size" viewBox="0 0 600 600">
      <g >
        <circle 
          v-for="(piece, idx) in doughnutData"
          :key="'doughnutCirclePiece' + idx"
          cx="300"
          cy="300"
          :r="radius"
          :stroke="doughnutData[idx].color"
          stroke-width="50"
          fill="transparent"
          :stroke-dasharray="dasharray"
          :stroke-dashoffset="piece.dashoffset"
          :transform="'rotate(' + piece.angle + ' 300 300)'"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import ColorConverter from "@/utils/ColorConverter.js"

export default {
  name: "SvgDoughnut",
  components: {
  },
  data() {
    return {
      chartSettings: {
        size: 200,
        backgroundColor: "#24313F"
      },
      centerX: 300,
      centerY: 300,
      radius: 240,
      dasharray: 2 * Math.PI * 240, /* 150 = half of 300 - always the same => viewport*/
    }
  },  
  computed: {
    barBottom() {
      return this.viewbox.height - this.chartSettings.xLabelHeight;
    },
    barColor() {
      return ColorConverter.hexToRgba(this.plot.color, this.chartSettings.alpha);
    },
    visibleItems() {
      return this.plot.data; //this.plot.data.filter(d => d.value != 0).length;
    },
    doughnutPieces() {
      return this.buildDonut();
    },
  },
  watch: {
    chartSize: function() {
      this.chartSettings.size = this.chartSize;
    },
    doughnutData: function() {
      this.setPieceData();
    },
  },
  props: {
    doughnutData: {
      data: [],
      color: []
    },
    chartSize: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.chartSettings.size = this.chartSize;
    this.setPieceData();
  },
  methods: {
    setPieceData() {
      const startAngle = -90;
      let filled = 0;
      this.doughnutData.forEach(piece => {
        piece.dashoffset = this.dasharray - (this.dasharray * piece.value / 100);
        piece.angle = (filled * 360 / 100) + startAngle;
        filled += piece.value;
      });     
    },

/*  version see below
    <svg :width="chartSettings.size" :height="chartSettings.size" viewBox="0 0 600 600">
      <g >
        <g 
          v-for="(piece, idx) in doughnutPieces"
          :key="'doughnutPiece' + idx"
        >
            <path :d="piece.d" :stroke="doughnutData[idx].color" fill="none" stroke-width="55" />
        </g>
      </g>
    </svg>

    getArcradius(cx, cy, radius, degrees) { 
    
      var radians = (degrees - 90) * Math.PI / 180.0;
      return { x: cx + (radius * Math.cos(radians)), y: cy + (radius * Math.sin(radians)) };
    },    
    buildDonut() {
      //const decimals = 4;
      let total = 0;
      const arr = [];
      var beg = 0;
      var end = 0;
      let count = 0;


      this.doughnutData.forEach(d => total += d.value);

      this.doughnutData.forEach((item, idx) => {
        const tmp = {};
        let p = Math.floor((((item.value + 1) / total) * 100));

        count += p;

        if (idx === (this.doughnutData.length - 1) && count < 100) p = p + (100 - count);

        end = beg + ((360 / 100) * p);
        
        tmp.index = idx;
        tmp.value = item.value;
        tmp.data = item;
        
        const b = this.getArcradius(this.centerX, this.centerY, this.radius, end);
        const e = this.getArcradius(this.centerX, this.centerY, this.radius, beg);
        const la = (end - beg) <= 180 ? 0 : 1;
        console.log("beg, end, b, e", beg, end, b, e);

        //tmp.d = ['M', b.x.floor(decimals), b.y.floor(decimals), 'A', this.radius, this.radius, 0, la, 0, e.x.floor(decimals), e.y.floor(decimals)].join(' ');
        tmp.d = ['M', Math.floor(b.x), Math.floor(b.y) , 'A', this.radius, this.radius, 0, la, 0, Math.floor(e.x), Math.floor(e.y) ].join(' ');

        arr.push(tmp);

        beg = end;
      });

      return arr;

      //for (var i = 0; i < this.data.length; i++) {
        //var item = this.data[i];
        //var tmp = {};

        //var p = (((item.value + 1) / total) * 100).floor(2);

        //count += p;

        //if (i === length - 1 && count < 100)
        //  p = p + (100 - count);

        //end = beg + ((360 / 100) * p);
        //tmp.index = i;
        //tmp.value = item.value;
        //tmp.data = item;

        //var b = this.getArcradius(cx, cy, radius, end);
        //var e = this.getAcradius(cx, cy, radius, beg);
        //var la = (end - beg) <= 180 ? 0 : 1;

        //tmp.d = ['M', b.x.floor(decimals), b.y.floor(decimals), 'A', radius, radius, 0, la, 0, e.x.floor(decimals), e.y.floor(decimals)].join(' ');
        //arr.push(tmp);
        //beg = end;
      //}

      //return arr;
  } */

/* // USAGE
(function(svg) {

var data = [{ value: 33 }, { value: 20 }, { value: 80 }, { value: 48 }];
var centerX = 300;
var centerY = 300;
var radius = 250;
var color = ['#F6BB42', '#8CC152', '#3BAFDA', '#967ADC', '#DA4453'];

var arr = Donut(centerX, centerY, radius, data);
for (var i = 0; i < arr.length; i++) {
  var item = arr[i];
  svg.asvg('<g><path d="{0}" stroke="{1}" fill="none" stroke-width="50" /></g>'.format(item.d, color[i]));
}

})($('#svg')); */

  }

};
</script>