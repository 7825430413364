<template>
    <div class="overlay" :class="{ 'is-active': active, 'is-visible': visible }">
        <div class="loginbox" :style="style" :class="{ 'is--active': active, 'is--visible': visible }" @click.self="handleWrapperClick">
            <button class="button--icon button--close" @click="handleCloseClick" >
                <i class="svgicon svgicon--close">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
                    </svg>
                </i>
            </button>
            <div class="loginbox__inner">
            <header class="loginbox__header">
                <h3>{{  getContent('CONF_TEXT_LOGIN_HEADLINE', true) }}</h3>
            </header>
            <form @submit.prevent="submit" novalidate class="form--login" @keyup="onFormKeyup">
                <div class="form-property form-property--error" v-if="isError">
                    <p class="form__error">Fehler!</p>
                </div>
                <div class="form-property">
                    <label for="user">{{ getContent('CONF_TEXT_LOGIN_NAME', true) }}</label>
                    <input type="email" class="form-control" id="user" v-model.lazy.trim="form.user" @blur="onFieldBlur('user')" v-bind:class="getFieldClasses('user')" />
                </div>
                <div class="form-property">
                    <label for="pass">{{ getContent('CONF_TEXT_LOGIN_PASSWORD', true) }}</label>
                    <input type="password" class="form-control" id="pass" v-model.lazy.trim="form.pass" @blur="onFieldBlur('pass')" v-bind:class="getFieldClasses('pass')"/>
                </div>
                <div class="form-property form-property--hidden">
                    <input type="hidden" class="form-control is--hidden" id="logintype" v-model.lazy.trim="form.logintype" />
                    <input type="hidden" class="form-control is--hidden" id="pid" v-model.lazy.trim="form.pid" />
                </div>
                <div class="form-property form-property--action">
                    <a href="https://www.myidm.at/administration/user/forgot-password" target="_blank" class="button button--dense">
                      {{  getContent('CONF_TEXT_LOGIN_FORGOT_PASSWORD', true) }}
                    </a>
                    <!-- <button class="button--dense" @click.prevent="onForgotPassword" :disabled="submitting">
                        
                    </button> -->
                    <button v-if="!submitting" class="button--primary" type="submit" @click.prevent="onSubmit" :disabled="submitting">
                      {{  getContent('CONF_TEXT_LOGIN_BUTTON', true) }}
                    </button>
                    <span v-if="submitting"  v-html="loaderSVG" class="form-loader"></span>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
//import Store from "@/store";
import { required } from "vuelidate/lib/validators";
//import axios from "axios";
//import qs from "qs";
import { LoginController, ContentController } from "@/controller";

//import { Api } from "@/api/";

export default {
  name: "LoginForm",
  data() {
    return {
      isError: false,
      errors: [],
      submitting: false,
      form: {
        user: "",
        pass: "",
        logintype: "login",
        pid: 112
      },
      visible: false,
      active: false,
      loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
    };
  },
  computed: {
    ...mapGetters(["gUser"]),
    style() {
      return this.active ? "display: block;" : "display: none";
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      this.submit();
    },
    onFormKeyup(ev) {
      this.isError = false;
      if (ev.key == "Enter" || ev.code == "Enter" || ev.keyCode==13) {
        this.submit();
      }
    },
    onForgotPassword() {
        console.log("onForgotpassword");
    },
    async submit() {
      this.submitting = true;
      const loginSuccess = await LoginController.doLogin(this.form.user, this.form.pass);
      this.submitting = false;
      if (loginSuccess) {
        this.doClose();
      } else {
        this.isError = true;
      }
    },
    enableSubmitLoader() {
      this.submitting = true;
    },
    disableSubmitLoader() {
      this.submitting = false;
    },
    /* sendFormData() {
      this.enableSubmitLoader();
      const formData = qs.stringify(this.form, { parseArrays: false });
      axios
        .post(Api.BACKEND_URL + Api.LOGIN, formData)
        .then(response => {
          this.submitSuccess(response);
          this.disableSubmitLoader();
        })
        .catch(error => {
          //console.log(error);
          Store.dispatch("logout");
          this.submitError(error);
          this.disableSubmitLoader();
        });
    }, */
     /* submitSuccess(response) {
      console.log(response);
      if (response.data.success) {
        Store.dispatch("setLogin", true);
        Store.dispatch("setUser", response.data.user);
        this.isError = false;
        setTimeout(() => {
          window.location = "/";
        }, 100);
        this.doClose();
      } else {
        this.errorHeader = "error.invalidFields";
        this.errors = response.data.errors;
        this.isError = true;
        Store.dispatch("logout");
      }
      this.form.pass = "";
    }, */
    /* submitError() {
      this.errorHeader = "error.general";
      this.errors = [{ field: null, message: "error.generalMessage" }];
      this.isError = true;
    }, */
    /* validationError() {
      this.errorHeader = "error.invalidFields";
      this.errors = this.getErrors();
      this.isError = true;
    }, */
    isErrorField(field) {
      try {
        if (this.getValidationField(field).$error) {
          return true;
        }
      } catch (error) {
        //continue
      }
      return this.errors.some(el => el.field === field);
    },
    getErrors() {
      let errors = [];
      for (const field of Object.keys(this.form)) {
        try {
          if (this.getValidationField(field).$error) {
            errors.push({ field: field, message: null });
          }
        } catch (error) {
          //continue
        }
      }
      return errors;
    },
    getFieldClasses(field) {
      return { "is-invalid": this.isErrorField(field) };
    },
    getCharactersLeft(field) {
      try {
        return (
          this.getValidationField(field).$params.maxLength.max -
          this.form[field].length
        );
      } catch (error) {
        return 0;
      }
    },
    getValidationField(field) {
      if (this.$v.form[field]) {
        return this.$v.form[field];
      }
      throw Error("No validation for field " + field);
    },
    onFieldBlur(field) {
      try {
        this.getValidationField(field).$touch();
        if (this.getValidationField(field).$error) {
          if (!this.errors.some(el => el.field === field)) {
            this.errors.push({ field: field, message: null });
          }
        } else {
          this.errors = this.errors.filter(el => el.field !== field);
        }
      } catch (error) {
        //continue
      }
    },
    reload() {
      window.location = "";
    },
    initShow() {
      console.log("[LoginForm] initShow");
    },
    handleWrapperClick() {
      console.log("handleWrapperClick");
      this.doClose();
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
        this.$loginForm.close();
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }        
  },
  validations: {
    form: {
      user: { required },
      pass: { required }
    }
  }
};
</script>
